//____Overview________________________________________________________________________________________________________________________

/*
The End page informs the user of the successful data transmission to the AWS Dynamo DB and reminds the user to close the tab.
*/

//____Imports________________________________________________________________________________________________________________________

//Import AWS resources (includes components of the react framework)
import React from "react";
import { Image, View, Flex, Text } from "@aws-amplify/ui-react";
import { useLocation } from "react-router-dom";
import { Analytics } from "aws-amplify";
import { Auth } from "aws-amplify";

//Import own resources: Images, predefined text-elements, CSS-styles, constants
import contiLogo_gif from "../assets/images/Continental_AG_logo_mov.gif";
import * as Constants from "./constants";
import textBase from "../textBase.json"; //Json file includes all text elements (translated in 5 languages)

//____Main________________________________________________________________________________________________________________________

Analytics.configure({ disabled: true }); //disable AWS Analytics

var signOutState = 0;

/**
 *  The End page informs the user of the successful data transmission to the AWS Dynamo DB and reminds the user to close the tab.
 *  @module Endpage */

/** @function */
export default function End() {
  //get data from main page via useLocation
  const location = useLocation();
  var selectedLanguage = location.state; //do not need the whole dataframe, only the selected language

  /**
   * This function signs out the current user via AWS Auth Service.
   *
   * @name signOut
   * @memberof module:Endpage
   * @return {boolean} 0: signout was not successfull, 1: signout was successfull
   * @example
   *
   *     signOut()
   */
  async function signOut() {
    try {
      await Auth.signOut();
      //console.log("Signed out successfully!");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  if (signOutState === 0) {
    signOut();
    signOutState = 1; //makes sure that the signOut-function is executed once
  }

  //____User Interface________________________________________________________________________________________________________________________

  return (
    <View
      width={Constants.WINDOW_WIDTH_FULL}
      left={Constants.WINDOW_LEFT}
      height="auto" //dynamically resizes the page (horizontally)
      overflow="hidden"
      position="relative"
      backgroundColor="rgba(255,255,255,1)"
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <Flex direction="column">
        <Image
          width="202px"
          height="62px"
          position="relative"
          left="5%"
          src={contiLogo_gif} //GIF of the Continental logo
        ></Image>
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Text
            className="text-element" //custom CSS style => see styles.css
            width={Constants.WINDOW_WIDTH}
            children={textBase["endPage"][selectedLanguage]["acknowledgement"]}
          ></Text>

          <Text
            className="text-element" //custom CSS style => see styles.css
            width={Constants.WINDOW_WIDTH}
            fontSize="40px"
            fontWeight="600"
            children={textBase["endPage"][selectedLanguage]["thanks"]}
          ></Text>

          <Text
            className="text-element" //custom CSS style => see styles.css
            width={Constants.WINDOW_WIDTH}
            children={textBase["endPage"][selectedLanguage]["closingInfo"]}
          ></Text>
        </Flex>
      </Flex>
    </View>
  );
}
