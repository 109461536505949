//____Overview________________________________________________________________________________________________________________________

/*
The Admin Page provides three functions.
1. Create new users, the user can define the number of new users => the credentials including usernames and passwords are available via auto download csv file. 
2. Create new QR codes, the user can define the number of new QR codes => each QR code is automatically downloaded as a .svg-file. You also get a .csv-file with all QR code IDs.
3. Get 1. a list of all workshop users and 2. a list of all admin users (both as a separate .csv file). This relates to the two usergroups in AWS Cognito: "Workshop" and "Admin" 

With the help of the AWS SDK the script is able to automatically setup (signUp) new users in AWS Cognito.
ONLY admins are allowed to use the admin page - this is technically ensured by checking the user group of the current cognito user.
There are two Cognito Groups:
1. Workshop Users 
2. Admin Users (allowed to enter the AdminPage => to this group belongs a separate group policy that allows the execution of admin AWS SDK functions (e.g. adminAddUserToGroup()))
*/

//____Imports________________________________________________________________________________________________________________________

//Import AWS resources (includes components of the react framework)
import React from "react";
import { useStateMutationAction } from "@aws-amplify/ui-react/internal";
import {
  Image,
  Button,
  TextField,
  View,
  Alert,
  Text,
  Flex,
  Divider,
} from "@aws-amplify/ui-react";
import { useState, useEffect } from "react";
import { Analytics } from "aws-amplify";
import { Amplify } from "aws-amplify";
import awsConfig from "../awsConfig.json";
import { DataStore } from "@aws-amplify/datastore";
import { QRcode } from "../models";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";

//Import other functionalities:
//To interpret markdown documents
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
//To create QR codes
import QRCodeStyling from "qr-code-styling";

//Import own resources: Images, predefined text-elements, CSS-styles, constants
import contiLogo_img from "../assets/images/Continental_AG_logo.svg";
import "./styles.css"; //customized styles for buttons and alerts
import * as Constants from "./constants"; //predefined constant variables, e.g. window width
import textBase from "../textBase.json"; //Json file that includes all text elements (translated in 5 languages)
import datapolicyDoc from "../assets/legal/copyright.md"; //exchanged data policy with the copyright information

//____Main________________________________________________________________________________________________________________________

Analytics.configure({ disabled: true }); //disable AWS Analytics

var warningMsg = "";
var tableData = [];

var AWS = require("aws-sdk"); //AWS instance needed for the execution of the SDK functions

//The @-keywords are used for the JS documentation (JSDoc)
/**
 * The Admin Page provides three functions.
 * 1. Create new users, the user can define the number of new users => the credentials including usernames and passwords are available via auto download csv file.
 * 2. Create new QR codes, the user can define the number of new QR codes => each QR code is automatically downloaded as a .svg-file. You also get a .csv-file with all QR code IDs.
 * 3. Get 1. a list of all workshop users and 2. a list of all admin users (both as a separate .csv file). This relates to the two usergroups in AWS Cognito: "Workshop" and "Admin"
 *
 * With the help of the AWS SDK the script is able to automatically setup (signUp) new users in AWS Cognito.
 * ONLY admins are allowed to use the admin page - this is technically ensured by checking the user group of the current cognito user.
 * There are two Cognito Groups:
 * 1. Workshop Users
 * 2. Admin Users (allowed to enter the AdminPage => to this group belongs a separate group policy that allows the execution of admin AWS SDK functions (e.g. adminAddUserToGroup()))
 * @module Adminpage */

/** @function */
export default function TDMwebAppAdmin() {
  const [numberOfUsers, setNumberOfUsers] = useStateMutationAction("1"); //set default value to 1
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [numberOfcodes, setNumberOfcodes] = useStateMutationAction("1"); //set default value to 1
  const [isInfoAlertVisible, setIsInfoAlertVisible] = useState(false);
  const [isDataPolicyVisible, setIsDataPolicyVisible] = useState(false);
  const [isImprintVisible, setIsImprintVisible] = useState(false);
  const [dataPolicy, setDataPolicy] = useState("");

  const selectedLanguage = "english"; //default language - the TDM Adminpage is only available in english!

  // Fetch data policy (the data policy is a markdown file that needs to get decoded)
  useEffect(() => {
    fetch(datapolicyDoc)
      .then((res) => res.text())
      .then((text) => setDataPolicy(text));
  });

  //____General Functions________________________________________________________________________________________________________________________

  /**
   * This function validated the user input and generates alert messages for the user.
   *
   * @name checkDataInput
   * @memberof module:Adminpage
   * @param {string} mode - specification of the input field
   * @param {string} value - current value of the input field
   * @return {boolean} 0: invalid user input, 1: valid user input
   *
   * @example
   *
   *     checkDataInput("numberOfNewUsers", 2)
   */
  async function checkDataInput(mode, value) {
    warningMsg = "";

    //Check user input for the first field: number of new users to be generated
    if (mode === "numberOfNewUsers") {
      //Check if the value is (not) a number
      if (isNaN(value)) {
        warningMsg = "Please enter a number!";
      }
      //Check if the input exceeds the maximum number of users (that can be generated all at once)
      else if (Number(value) > Constants.MAXUSERS) {
        warningMsg =
          "The maximum number of users generated at once is" +
          String(Constants.MAXUSERS) +
          "!";
      }
      //The number of users should be not negative or zero
      else if (Number(value) <= 0) {
        warningMsg = "The minimum number is 1!";
      }
    }

    //Check user input for the second field: number of new QR codes to be generated
    if (mode === "numberOfcodes") {
      //Check if the value is (not) a number
      if (isNaN(value)) {
        warningMsg = "Please enter a number!";
      }
      //Check if the input exceeds the maximum number of QR codes (that can be generated all at once)
      else if (Number(value) > Constants.MAXQRCODES) {
        warningMsg = "The maximum number of users generated at once is 50!";
      }
      //The number of users should be not negative or zero
      else if (Number(value) <= 0) {
        warningMsg = "The minimum number is 1!";
      }
    }

    //If the input is valid (=> length of the warning message equals zero): do not display the alert, else: display the alert element with the latest warning message
    if (warningMsg.length === 0) {
      setIsAlertVisible(false);
      return 1;
    } else {
      setIsAlertVisible(true);
      return 0;
    }
  }

  /**
   * This function creates a random string with n capitalized letters, k lowercased letters and t numbers. You can also define a string that is placed in front of the random string.
   *
   * @name generateRandomString
   * @memberof module:Adminpage
   * @param {number} numberCapitalizedLetters - defines the number of capitalized letters in the random string
   * @param {number} numberLowercasedLetters - defines the number of lowercased letters in the random string
   * @param {number} numberNumbers - defines the number of numbers in the random string
   * @param {string} [preString=""] - optional string that is placed in front of the random string (e.g. used for the usernames: ws1298, "ws" is the preString)
   * @return {string} desired random string
   *
   * @example
   *
   *     generateRandomString(2,2,4)
   */
  function generateRandomString(
    numberCapitalizedLetters,
    numberLowercasedLetters,
    numberNumbers,
    preString = ""
  ) {
    let result = "";

    //Get "random" number of capitalized letters (no true randomness!)
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var charactersLength = characters.length;
    for (let i = 0; i < numberCapitalizedLetters; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength)); //Explanation: https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
    }

    //Get "random" number of lowercased letters (no true randomness!)
    characters = "abcdefghijklmnopqrstuvwxyz";
    charactersLength = characters.length;
    for (let i = 0; i < numberLowercasedLetters; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength)); //Explanation: https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
    }

    //Get "random" number of numbers (no true randomness!)
    characters = "0123456789";
    charactersLength = characters.length;
    for (let i = 0; i < numberNumbers; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength)); //Explanation: https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
    }

    //console.log("Before shuffle: " + result);

    //Shuffle the generated result-String to change the hardcoded order of capitalized, lowercased letters and numbers
    result = result
      .split("")
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join("");
    //Add the preString in front of the generated string
    if (preString !== "") {
      result = preString + result;
    }

    //console.log("After shuffle: " + result);

    return result;
  }

  /**
   * This function enables automatic downloads in the webbrowser. Therefore you need to define the name of the download file and the content to be downloaded.
   *
   * @name download
   * @memberof module:Adminpage
   * @param {string} filename - define the name of the download file (including type of the file e.g. .csv, .svg)
   * @param {string} text - text data to be downloaded
   *
   * @example
   *
   *     download("testData.csv","abc")
   */
  function download(filename, text) {
    var element = document.createElement("a"); //a is the official HTML download attribute/tag
    //set the content to be downloaded via "href" attribute
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text) //encode the textdata to get a standardized output format for href
    );
    element.setAttribute("download", filename); //set the given filename

    element.style.display = "none"; //we do not want to display anything
    document.body.appendChild(element); //add the generated download element temporary to the HTML document

    element.click(); //simulate a click to trigger the <a> download

    document.body.removeChild(element); //remove it from the body, because the element is not needed anymore
  }

  //____User Management Functions________________________________________________________________________________________________________________________

  /**
   * This function generates n new users in the AWS Cognito User Management System that can be directly used for the webapp. The function generates new (random) usernames and passwords
   * and stores them in a .csv file. Every username starts with "ws", short for workshop. After that the generateRandomString() function adds a random 4 digit number. The passwords include
   * 10 chars, a combination of capitalized and lowercased letters and numbers (AWS password policy). Before a user gets created, another functions check if the username already exists.
   * If this is the case, the function creates a new (random) username and checks the availability again. After that the user is grouped automatically to the "workshop"-Cognito usergroup.
   *
   * @name generateUser
   * @memberof module:Adminpage
   * @param {number} numberOfUsers - defines the desired number of new users
   *
   * @example
   *
   *     generateUser(2)
   */
  async function generateUser(numberOfUsers) {
    try {
      const users = await createUsernameAndPassword(numberOfUsers);
      /* This is the structure of "users" (in this case its static, we need a dynamic version because the number of new users depends on the user input)
      const users = [
        { username: "kk11", password: "ksdjfgla93riwJJJJk" },
        { username: "kk22", password: "ksdjfgla222riwJJJJk" },
        { username: "kk33", password: "ksdjfgla93riw33JJk" },
      ];
      */
      //console.log("Created users:", users);

      var userData = "Username,Password\n"; //define the first entry of the .csv-file => header

      //We need to confirm that we are allowed to use the AWS SDK functions that need admin privileges
      const mycredentials = await Amplify.Auth.currentCredentials();
      var cognitoidentityserviceprovider =
        new AWS.CognitoIdentityServiceProvider({
          region: awsConfig.region,
          credentials: mycredentials,
        });

      //"Loop" through all users to sign them up
      users.forEach((user) => {
        var params = {
          ClientId: awsConfig.clientId,
          Password: user.password, //randomly generated 10 char password (AWS Cognito password policy: Password minimum length 10 character(s), Password requirements: Contains at least 1 number, Contains at least 1 uppercase letter, Contains at least 1 lowercase letter
          Username: user.username, //randomly generated and non-existing username (via createUsernameAndPassword())
          UserAttributes: [
            {
              Name: "preferred_username", //AWS Cognito requirement
              Value: user.username,
            },
          ],
        };
        //Sign up the user with the given username and password
        cognitoidentityserviceprovider.signUp(params, function (err, data) {
          if (err) {
            // an error occurred => print it to the console
            console.log(err, err.stack);
            return;
          } else {
            //Define parameters for adding the newly generated user to the "workshop" group
            var cparams = {
              GroupName: "Workshop",
              UserPoolId: awsConfig.userPoolId,
              Username: user.username,
            };
            checkAvail(cparams, user.username); //checks if the user is available and adds the user to the group
          }
        });
        userData = userData + user.username + "," + user.password + "\n"; //creates the next line of the .csv-file
      });
    } catch (err) {
      console.error("Error:", err);
    }

    //After all users are created the .csv gets prepared for download
    var timestamp = new Date().toISOString(); //get current timestamp => timestamp is part of the filename => individual name
    var filename = "tdmWebappCredentials_" + timestamp.slice(0, -4) + "csv"; //build the filename
    download(filename, userData); //download the .csv-file with the newly generated users
  }

  /**
   * This function checks if the generated user is already available in the AWS Cognito system. If not, it waits for two seconds and checks again.
   * As soon as the user is available we add the user to the "workshop" group.
   * 
   * @name checkAvail
   * @memberof module:Adminpage
   * @param {string} cparams - set of parameters needed for the adminAddUserToGroup() AWS SDK function call
   * @param {string} userN - username
   * 
   * @example
   *
   *     checkAvail({
              GroupName: "Workshop",
              UserPoolId: awsConfig.userPoolId,
              Username: user.username,
            },"ws2312")
   */
  async function checkAvail(cparams, userN) {
    //We need to confirm that we are allowed to use the AWS SDK functions that need admin privileges
    const mycredentials = await Amplify.Auth.currentCredentials();
    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider(
      {
        region: awsConfig.region,
        credentials: mycredentials,
      }
    );
    //Define parameter for the "adminGetUser"-call
    var params = {
      UserPoolId: awsConfig.userPoolId,
      Username: userN,
    };

    //Check if the user is available or not
    cognitoidentityserviceprovider.adminGetUser(params, (err, data) => {
      if (err && err.code === "UserNotFoundException") {
        //Wait until user exists - sometimes Cognito does take some synchronization time => execute the function in 2 seconds again
        setTimeout(() => {
          checkAvail(cparams, userN);
        }, 2000);
      } else if (err) {
        //Another error occured => print it to the console
        console.error("Error: ", err);
      } else {
        //console.log("User available!");

        //Add the new user to the group
        cognitoidentityserviceprovider.adminAddUserToGroup(
          cparams,
          function (err, data) {
            if (err) {
              console.log(err, err.stack); //An error occurred => print it to the console
            } else {
              //console.log(data); //successful response
              //console.log("Added User to Group!");
            }
          }
        );
      }
    });
  }

  /**
   * This function creates based on the generateRandomString() function n random usernames and passwords. The usernames are verified.
   * If the same name already exists, a new one is generated and tested again.
   *
   * @name createUsernameAndPassword
   * @memberof module:Adminpage
   * @param {number} numberOfUsers - the number of users to be generated
   * @return {string} List of created users and passwords
   *
   * @example
   *
   *     createUsernameAndPassword(10)
   */
  async function createUsernameAndPassword(numberOfUsers) {
    //We need to confirm that we are allowed to use the AWS SDK functions that need admin privileges
    const mycredentials = await Amplify.Auth.currentCredentials();
    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider(
      {
        region: awsConfig.region,
        credentials: mycredentials,
      }
    );
    const userCreationPromises = [];

    //Generate n (=numberOfUsers) new users
    for (let i = 0; i < numberOfUsers; i++) {
      let username = generateRandomString(0, 0, 4, "ws"); //Username with 4 random digits and the prestring "ws"
      const password = generateRandomString(3, 3, 4, ""); //Password with 6 random letters and 4 digits

      const userCreationPromise = new Promise(async (resolve, reject) => {
        try {
          let userExists = true;

          //Execute the while loop until a username that does not already exist is found (userExists = false)
          while (userExists) {
            try {
              await cognitoidentityserviceprovider
                .adminGetUser({
                  UserPoolId: awsConfig.userPoolId,
                  Username: username,
                })
                .promise();
              //console.log("User exists, generate a new username");
              username = generateRandomString(0, 0, 4, "ws");
            } catch (err) {
              if (err.code === "UserNotFoundException") {
                //console.log("User does not exist, break out of the loop");
                userExists = false;
              } else {
                throw err; //Unexpected error, re-throw
              }
            }
          }
          resolve({ username, password }); //Promise system
        } catch (err) {
          reject(`Error creating user ${username}: ${err}`);
        }
      });
      //Add the current username and password to the list
      userCreationPromises.push(userCreationPromise);
    }
    try {
      const createdUsers = await Promise.all(userCreationPromises);
      return createdUsers;
    } catch (err) {
      console.error("Error creating users:", err);
      return [];
    }
  }

  /**
   * This function creates based on the generateRandomString() function n random usernames and passwords. The usernames are verified.
   * If the same name already exists, a new one is generated and tested again.
   * @name getAllUsers
   * @memberof module:Adminpage
   * @param {string} token - the SDK API call is limited to 60 elements, if its higher you get a token to get the next 60
   * @param {string} group - name of the desired group
   * @param {string} userNames - for multiple API calls (>60 users) this is the list of all users
   *
   * @example
   *
   *     getAllUsers(token, "workshop", "")
   */
  async function getAllUsers(token, group, userNames) {
    const mycredentials = await Amplify.Auth.currentCredentials();
    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider(
      {
        region: awsConfig.region,
        credentials: mycredentials,
      }
    );
    var params = {};
    if (userNames === "") {
      var userNames = group + "_Username\n"; //create the header of the .csv file
    }
    if (token === "") {
      //no token given
      params = {
        GroupName: group /* required */,
        UserPoolId: awsConfig.userPoolId /* required */,
        Limit: 50, //60 is the absolute max. for one API call, if there are more then 60 users in Cognito, you have to add a token to get the next bunch of users
      };
    } else {
      //token given => next API call
      params = {
        GroupName: group /* required */,
        UserPoolId: awsConfig.userPoolId /* required */,
        Limit: 50, //60 is the absolute max. for one API call, if there are more then 60 users in Cognito, you have to add a token to get the next bunch of users
        NextToken: token,
      };
    }
    cognitoidentityserviceprovider.listUsersInGroup(
      params,
      function (err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else {
          //console.log(data["Users"][0]); // successful response
          for (let i = 0; i < data["Users"].length; i++)
            userNames = userNames + data["Users"][i]["Username"] + "\n";
          //console.log(data["Users"]);
          if ("NextToken" in data) {
            console.log("nextToken!");
            getAllUsers(data["NextToken"], group, userNames);
          } else {
            var timestamp = new Date().toISOString(); //get current timestamp => timestamp is part of the filename => individual name
            var filename =
              "tdmWebapp_" +
              group +
              "_usernames_" +
              timestamp.slice(0, -4) +
              "csv"; //build filename with groupname and timestamp
            download(filename, userNames); //download the .csv with all users of the specified group
          }
          //console.log(data["NextToken"]);
        }
      }
    );
    //console.log(userNames);
  }

  //____QR Code Functions________________________________________________________________________________________________________________________

  /**
   * This function generates n new QR codes. In the first step we get a list of all existing QR code IDs to compare them with the randomly generated IDs and make sure that there are no duplicated IDs.
   * If this is the case we create a new random ID and check again. After that we push the ID to the database and generate the QR code. When all of them are generated, we provide a .csv file with all IDs
   * for download.
   *
   * @name generateQRcode
   * @memberof module:Adminpage
   * @param {number} numberOfCodes - number of QR codes to be generated
   *
   * @example
   *
   *     generateQRcode(10)
   */
  async function generateQRcode(numberOfCodes) {
    getQRcodeIDdata(); //get a current snapshot of the database table with all IDs
    var tableDataLoop = "ID\n";
    for (let i = 0; i < Number(numberOfCodes); i++) {
      //check if randomly generated code already exists
      var newID = generateRandomString(0, 3, 3, ""); //generate random ID with 3 lowercased letters and 3 numbers
      while (tableData.includes(newID) || tableDataLoop.includes(newID)) {
        newID = generateRandomString(0, 3, 3, ""); //if ID already exists, create a new one
      }
      // tableDataLoop.push(newID); //stores the new ID in another list to prevent queuing the table again and again, this list is embedded in the while loop
      tableDataLoop = tableDataLoop + newID + "\n"; //creates the next line of the .csv-file
      createDatabaseEntry(newID); //pushes the new ID to the database
      createQRCode(newID); //generates the QR code and provide it as a svg-download
    }
    var timestamp = new Date().toISOString(); //get current timestamp => timestamp is part of the filename => individual name
    var filename = "tdmWebappQRcodeIDs_" + timestamp.slice(0, -4) + "csv"; //bulld the filename
    download(filename, tableDataLoop); //provides a csv that includes all newly generated IDs
  }

  /**
   * This function creates an new entry in the AWS Dynamo DB. All IDs generated so far are stored in the table "QRCode".
   * @name createDatabaseEntry
   * @memberof module:Adminpage
   * @param {string} newQRcodeID - QR Code ID
   *
   * @example
   *
   *     createDatabaseEntry("87uod6")
   */
  async function createDatabaseEntry(newQRcodeID) {
    try {
      //Setup new data entry
      await DataStore.save(
        new QRcode({
          qrCodeID: newQRcodeID,
        })
      );
    } catch (error) {
      console.log("Error pushing data: ", error);
    }
  }

  /**
   * This function creates an new entry in the AWS Dynamo DB. All IDs generated so far are stored in the table "QRCode".
   * @name createQRCode
   * @memberof module:Adminpage
   * @param {string} id - QR Code ID => identifies each dongle
   *
   * @example
   *
   *     createQRCode("87uod6")
   */
  function createQRCode(id) {
    //Add parameter
    const qrCode = new QRCodeStyling({
      width: 170,
      height: 170,
      type: "png",
      data: "https://tdm.continental-reifen.de/?qrc=" + id, //this is the "data" that is stored in the QR code
      image: "logo192.png", //locally stored image of the Conti-Logo (in the public folder, in parallel to the html file)
      backgroundOptions: {
        color: "white",
      },
      dotsOptions: {
        color: "#FFA510", //set the color of the dots to Conti-Orange
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 1,
      },
    });
    qrCode.download({ name: id, extension: "png" }); //automatically downloads the generated QR code
  }

  /**
   * This function requests all QR code IDs from the database and stores them in a global variable.
   * @name getQRcodeIDdata
   * @memberof module:Adminpage
   * @param {string} id - QR Code ID => identifies each dongle
   *
   * @example
   *
   *     getQRcodeIDdata()
   */
  async function getQRcodeIDdata() {
    //Get IDs from database
    const allIDs = await API.graphql({ query: queries.listQRcodes });
    //console.log(allIDs["data"]["listQRcodes"]["items"].length);
    //Mirror table of IDs
    var numberOfIDs = allIDs["data"]["listQRcodes"]["items"].length;
    for (let i = 0; i < numberOfIDs; i++) {
      tableData.push(allIDs["data"]["listQRcodes"]["items"][i]["qrCodeID"]); //tableData is still a global variable, somehow it did not work as a function parameter
    }
  }

  //____User Interface________________________________________________________________________________________________________________________

  return (
    <View //this "global" view includes all components
      width={Constants.WINDOW_WIDTH_FULL}
      left={Constants.WINDOW_LEFT}
      height="auto" //dynamically resizes the page (vertically)
      overflow="hidden"
      position="relative"
      backgroundColor="rgba(255,255,255,1)"
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <Flex direction="column">
        <Image
          width="202px"
          height="62px"
          left="5%"
          position="relative"
          src={contiLogo_img} //Continental logo at the top of the page
        ></Image>

        <Flex
          direction="column"
          position="relative"
          top="10px"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Text
            className="text-element-admin" //custom CSS style => see styles.css
            width={Constants.WINDOW_WIDTH}
            top="10px"
            children="Welcome to the Admin page of the TDM WebApp! You can create new users and generate QR codes. The credentials are provided via a CSV file, that downloads automatically. The maximum number of users that can be generated at once is limited to 30. The IDs of the QR codes are getting stored in a CSV file that gets downloaded automatically as well. The limit is set to 50." //hardcoded => no translation, always english
          ></Text>

          {isAlertVisible ? (
            <Alert
              className="info-alert" //custom CSS style => see styles.css
              display="flex"
              variation="error"
              position="relative"
              width={Constants.WINDOW_WIDTH}
              justifyContent="center"
              alignItems="center"
              children={warningMsg}
              isDismissible={false}
              hasIcon={true}
            ></Alert>
          ) : null}

          <Text
            className="text-element-header" //custom CSS style => see styles.css
            width={Constants.WINDOW_WIDTH}
            children="Generate New Users" //hardcoded => no translation, always english
          ></Text>

          <Text
            className="text-element-admin" //custom CSS style => see styles.css
            width={Constants.WINDOW_WIDTH}
            children="How many new users do you want to generate? Please enter the number of users in the field below and press the button to confirm your input." //hardcoded => no translation, always english
          ></Text>

          <TextField
            className="text-field-tread-depth" //custom CSS style => see styles.css, in this case the style of the input field for the tread depth is reused
            size="small"
            defaultValue="1" //default value is set to 1 because 0 / empty field is no valid option
            onChange={(event) => {
              if (event.target.value === "") {
                setNumberOfUsers(""); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
              } else {
                setNumberOfUsers(event.target.value);
              }
              checkDataInput("numberOfNewUsers", event.target.value); //input validation (e.g. check if the input is a number)
            }}
          ></TextField>

          <Button
            className="commit-button" //custom CSS style => see styles.css
            width={Constants.WINDOW_WIDTH}
            top="0px"
            justifyContent="center"
            alignItems="center"
            display="flex"
            children={
              Number(numberOfUsers) > 1
                ? "Generate " + String(numberOfUsers) + " Users"
                : "Generate " + String(numberOfUsers) + " User"
            }
            onClick={() => {
              if (checkDataInput("numberOfNewUsers", numberOfUsers)) {
                generateUser(numberOfUsers); //create new user(s) only if the user input is valid: number between 1 and [limit], e.g. 1-50
              }
            }}
          ></Button>
        </Flex>

        <Flex
          direction="column"
          justifyContent="center"
          gap="6px"
          alignItems="center"
          display="flex"
        >
          <Text
            className="text-element-header" //custom CSS style => see styles.css
            width={Constants.WINDOW_WIDTH}
            children="Generate QR Codes"
          ></Text>

          <Text
            className="text-element-admin" //custom CSS style => see styles.css
            width={Constants.WINDOW_WIDTH}
            children="How many new QR codes do you want to generate? Please enter the number of QR codes in the field below and press the button to confirm your input."
          ></Text>

          <TextField
            className="text-field-tread-depth" //custom CSS style => see styles.css, in this case the style of the input field for the tread depth is reused
            size="small"
            defaultValue="1"
            onChange={(event) => {
              if (event.target.value === "") {
                setNumberOfcodes(""); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
              } else {
                setNumberOfcodes(event.target.value);
              }
              checkDataInput("numberOfcodes", event.target.value);
            }}
          ></TextField>

          <Button
            className="commit-button" //custom CSS style => see styles.css
            width={Constants.WINDOW_WIDTH}
            top="0px"
            justifyContent="center"
            alignItems="center"
            display="flex"
            children={
              Number(numberOfcodes) > 1
                ? "Generate " + String(numberOfcodes) + " QR Codes"
                : "Generate " + String(numberOfcodes) + " QR Code"
            }
            onClick={() => {
              if (checkDataInput("numberOfcodes", numberOfcodes))
                generateQRcode(numberOfcodes); //create new QR code(s) only if the user input is valid: number between 1 and [limit], e.g. 1-50
            }}
          ></Button>
        </Flex>
        <Flex
          direction="column"
          justifyContent="center"
          gap="6px"
          alignItems="center"
          display="flex"
        >
          <Text
            className="text-element-header" //custom CSS style => see styles.css
            width={Constants.WINDOW_WIDTH}
            children="List Users"
          ></Text>
          <Text
            className="text-element-admin" //custom CSS style => see styles.css
            width={Constants.WINDOW_WIDTH}
            children="Please press the button below to get a list of all users registered in the TDM webapp. You automatically get two lists: the first includes all workshop usernames and the second shows all admin users of the TDM webapp. Both files are .csv files."
          ></Text>

          <Button
            className="commit-button" //custom CSS style => see styles.css
            width={Constants.WINDOW_WIDTH}
            top="0px"
            justifyContent="center"
            alignItems="center"
            display="flex"
            children={"Download List of TDM Users"}
            onClick={() => {
              getAllUsers("", awsConfig.workshopGroupName, ""); //get all usernames of the workshop group
              getAllUsers("", awsConfig.adminGroupName, ""); //get all usernames of the admin group
            }}
          ></Button>
        </Flex>
        <Flex
          direction="column"
          justifyContent="center"
          gap="6px"
          alignItems="center"
          display="flex"
        >
          <Divider
            label=""
            orientation="horizontal"
            width={Constants.WINDOW_WIDTH}
          ></Divider>
          <Button
            className="imprint-button" //custom CSS style => see styles.css
            size="small"
            children={textBase["legal"][selectedLanguage]["imprint"][0]}
            width={Constants.WINDOW_WIDTH}
            onClick={() => {
              setIsImprintVisible(!isImprintVisible); //activates (=shows) the imprint
              setIsDataPolicyVisible(false); //hides the other data policy panel
              setIsInfoAlertVisible(false); //hides the other info alert panel
            }}
          ></Button>

          <Button
            className="imprint-button"
            size="small"
            children={textBase["legal"][selectedLanguage]["copyright"][0]}
            width={Constants.WINDOW_WIDTH}
            onClick={() => {
              setIsDataPolicyVisible(!isDataPolicyVisible); //see above
              setIsImprintVisible(false); //see above
              setIsInfoAlertVisible(false); //see above
            }}
          ></Button>
        </Flex>

        <Flex
          direction="column"
          position="relative"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          {isImprintVisible ? (
            <Alert
              className="info-alert"
              width={Constants.WINDOW_WIDTH}
              heading={textBase["legal"][selectedLanguage]["imprint"][0]} //here the manually layout of textelements based on HTML tags is tested - otherwise you can use a markdown file => but in this case you need to deal with encoding and consider translations
            >
              <p>{textBase["legal"][selectedLanguage]["imprint"][1]}</p>
              {textBase["legal"][selectedLanguage]["imprint"][2]}
              <p></p>
              {textBase["legal"][selectedLanguage]["imprint"][3]}
              <p></p>
              {textBase["legal"][selectedLanguage]["imprint"][4]}
              <p></p>
              {textBase["legal"][selectedLanguage]["imprint"][5]}
              <p></p>
              {textBase["legal"][selectedLanguage]["imprint"][6]}
            </Alert>
          ) : null}

          {isDataPolicyVisible ? (
            <Alert
              className="info-alert"
              width={Constants.WINDOW_WIDTH}
              heading={textBase["legal"][selectedLanguage]["copyright"][0]}
            >
              <Markdown
                children={dataPolicy}
                remarkPlugins={[remarkGfm]} //not datapolicy but copyright! => dataPolicy as a placeholder for website information
              />
            </Alert>
          ) : null}
        </Flex>
      </Flex>
    </View>
  );
}
