import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import awsconfig from "./aws-exports";
import Amplify from "@aws-amplify/core";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react";

import FrontL from "./pages/FrontL";
import FrontR from "./pages/FrontR";
import RearL from "./pages/RearL";
import RearR from "./pages/RearR";
import NoPage from "./pages/NoPage";
import End from "./pages/End";
import Main from "./pages/Main";
import Login from "./pages/Login";
import TDMwebAppAdmin from "./pages/TDMwebAppAdmin";

Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/Main" element={<Main />} />
      <Route path="/FL" element={<FrontL />} />
      <Route path="/FR" element={<FrontR />} />
      <Route path="/RL" element={<RearL />} />
      <Route path="/RR" element={<RearR />} />
      <Route path="*" element={<NoPage />} />
      <Route path="/End" element={<End />} />
      <Route path="/TDMwebAppAdmin" element={<TDMwebAppAdmin />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
