// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { QRcode, WorkshopVisit } = initSchema(schema);

export {
  QRcode,
  WorkshopVisit
};