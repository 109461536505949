//____Overview________________________________________________________________________________________________________________________

/*
The Login Page is the start page of the TDM webapp (this page is rendered after you have scanned the QR code) and allows the user to login with the provided credentials (username and password).
Additionally you can select the language (5 options: english, german, french, italian, spanish).
If you have forgotten your password, send an email to the service email address.
After you have successfully logged in, you will be redirected to the main page. 
*/

//____Imports________________________________________________________________________________________________________________________

//Import AWS resources (includes components of the react framework)
import React from "react";
import { useStateMutationAction } from "@aws-amplify/ui-react/internal";
import {
  Image,
  Button,
  PasswordField,
  TextField,
  View,
  Alert,
  Text,
  Flex,
  Divider,
  Link,
} from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Analytics } from "aws-amplify";

//Import other functionalities:
//To interpret markdown documents
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

//Import own resources
import contiLogo_img from "../assets/images/Continental_AG_logo.svg";
import flags_img from "../assets/images/flagsCenteredWide.svg";
import "./styles.css"; //customized styles for buttons and alerts
import * as Constants from "./constants";
import textBase from "../textBase.json"; //Json file includes all text elements (translated in 5 languages)
import datapolicyDoc from "../assets/legal/copyright.md"; //tdmDataPolicy

//____Main________________________________________________________________________________________________________________________

Analytics.configure({ disabled: true }); //disable AWS Analytics

//Define data structure for gathering the input data => you can modifiy this dataframe on each page of the webapp (as a parameter in the navigation)
var dataframe = {
  user: {
    name: "",
    pass: "",
  },
  main: {
    obdID: "",
    language: "english",
    country: 0,
    workshopName: "",
    carManufacturer: 0,
    engineType: 0,
    workshopAction: 0,
  },
  fl: {
    validInput: "",
    dotNumber: ["", "", "", ""],
    tireManufacturer: "",
    tireModellline: "",
    tireSize: ["", "", ""],
    innerTD: ["", ""],
    centerTD: ["", ""],
    outerTD: ["", ""],
  },
  fr: {
    validInput: "",
    dotNumber: ["", "", "", ""],
    tireManufacturer: "",
    tireModellline: "",
    tireSize: ["", "", ""],
    innerTD: ["", ""],
    centerTD: ["", ""],
    outerTD: ["", ""],
  },
  rl: {
    validInput: "",
    dotNumber: ["", "", "", ""],
    tireManufacturer: "",
    tireModellline: "",
    tireSize: ["", "", ""],
    innerTD: ["", ""],
    centerTD: ["", ""],
    outerTD: ["", ""],
  },
  rr: {
    validInput: "",
    dotNumber: ["", "", "", ""],
    tireManufacturer: "",
    tireModellline: "",
    tireSize: ["", "", ""],
    innerTD: ["", ""],
    centerTD: ["", ""],
    outerTD: ["", ""],
  },
};

//Define the position of the buttons for the language menu for different screen sizes
const languageBarPosition = {
  english: {
    base: "-38%", //for responsive design
    small: "-128px",
    medium: "-128px",
    large: "-128px",
    xl: "-128px",
    xxl: "-128px",
  },
  german: {
    base: "-19%",
    small: "-64px",
    medium: "-64px",
    large: "-64px",
    xl: "-64px",
    xxl: "-64px",
  },
  italian: {
    base: "0%",
    small: "-2px",
    medium: "-2px",
    large: "-2px",
    xl: "-2px",
    xxl: "-2px",
  },
  spanish: {
    base: "19%",
    small: "62px",
    medium: "62px",
    large: "62px",
    xl: "62px",
    xxl: "62px",
  },
  french: {
    base: "38%",
    small: "125px",
    medium: "125px",
    large: "125px",
    xl: "125px",
    xxl: "125px",
  },
};

//Get the query parameter in the request URL: qrc (=> indirect Dongle identification, qrc short for QR Code)
//Example URL: http://localhost:3000/?qrc=5432
var queryParams = new URLSearchParams(window.location.search);
var idValue = queryParams.get("qrc");
//console.log("OBD Dongle ID: " + idValue);

var selectedLanguage = "english"; //Default language
var loaded = 1; //used for functions that need to be executed once at the beginning

//The @-keywords are used for the JS documentation (JSDoc)
/**
 *
 *The Login Page is the start page of the TDM webapp (this page is rendered after you have scanned the QR code) and allows the user to login with the provided credentials (username and password).
 *Additionally you can select the language (5 options: english, german, french, italian, spanish).
 *If you have forgotten your password, send an email to the service email address.
 *After you have successfully logged in, you will be redirected to the main page.
 *
 * @module LoginPage */

/** @function */
export default function LoginPage() {
  const [userName, setUserName] = useStateMutationAction("");
  const [userPass, setUserPass] = useStateMutationAction("");
  const [language, setLanguage] = useStateMutationAction("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isInfoAlertVisible, setIsInfoAlertVisible] = useState(false);
  const [isDataPolicyVisible, setIsDataPolicyVisible] = useState(false);
  const [isImprintVisible, setIsImprintVisible] = useState(false);
  const navigate = useNavigate();

  var AWS = require("aws-sdk");
  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    region: "eu-central-1",
  });

  const [dataPolicy, setDataPolicy] = useState("");
  // Fetch data policy
  useEffect(() => {
    fetch(datapolicyDoc)
      .then((res) => res.text())
      .then((text) => setDataPolicy(text));
  });

  const navToMain = () => {
    dataframe.main["obdID"] = idValue;
    dataframe.main["language"] = selectedLanguage;
    navigate("/Main/", { state: dataframe });
  };

  /**
   * This function checks if the current QR code ID was already used in the last session. By storing the current QR code ID in the local webstorage, we resolve the "Bookmark"-Issue, where the user stores the link of the webapp as a bookmark in the webbrowser and does not scan the QR codes anymore.
   * In this case the ID remains the same and we cannot differentiate between the different dongles anymore, because the same ID is used. To be able to remind the user to scan the QR codes properly, we store the QR code IDs in a variable in the local webstorage and compare it to the current QR code ID.
   * If the last QR code ID equals the current ID a popup reminds the user to scan the QR code again.
   *
   * @name checkLocalStorage
   * @memberof module:LoginPage
   * @param {string} id - current qr code id
   * @return {boolean} 0: id was used already before, 1: id is different to the one used before
   *
   * @example
   *
   *     checkDataInput("7562")
   */
  async function checkLocalStorage(id) {
    try {
      if (id) {
        let storedDongleID = localStorage.getItem("dongleID");
        if (storedDongleID === null) {
          //check if "dongleID" does exist in LocalStorage
          localStorage.setItem("dongleID", id); //if not: create dongleID and store the current ID
          //console.log("created local storage key");
        } else {
          if (storedDongleID === id) {
            //if the id that was stored the last time is the same as the new/current id: warning
            //console.log("Attention: have you scanned the QR code?");
            setIsAlertVisible("warningMsgBookmarkIssue");
            return 0;
          } else {
            setIsAlertVisible(false);
            return 1;
          }
          localStorage.setItem("dongleID", id);
        }
      } else {
        //console.log("ID is missing!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  //Execute the function once to check the QR code ID
  if (loaded) {
    checkLocalStorage(idValue);
    loaded = 0;
  }

  /**
   * This function validated the user input and generates alert messages for the user.
   *
   * @name signIn
   * @memberof module:LoginPage
   * @return {boolean} 0: error while logging in, 1: successfully logged in
   *
   * @example
   *
   *     signIn()
   */
  async function signIn() {
    try {
      const user = await Auth.signIn(userName, userPass);
      dataframe.user["name"] = userName;
      dataframe.user["pass"] = userPass;
      //console.log(user);
      console.log("Signed in successfully!");
      if (idValue) {
        //check if obd ID is available
        setIsAlertVisible(false);
        navToMain();
        return 1;
      } else {
        setIsAlertVisible("warningMsgID");
      }
    } catch (error) {
      console.log("Error signing in", error);
      setIsAlertVisible("warningMsg");
      return 0;
    }
  }

  return (
    <View
      width={Constants.WINDOW_WIDTH_FULL}
      left={Constants.WINDOW_LEFT}
      height="auto" //dynamically resizes the page (horizontally)
      overflow="hidden"
      position="relative"
      backgroundColor="rgba(255,255,255,1)"
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <Flex direction="column">
        <Image
          width="202px"
          height="62px"
          left="5%"
          position="relative"
          src={contiLogo_img}
        ></Image>

        <Flex
          direction="column"
          position="relative"
          top="10px"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Text
            className="text-element"
            width={Constants.WINDOW_WIDTH}
            top="10px"
            children="Please choose your language:" //no translation, always english
          ></Text>

          <Image
            width={Constants.WINDOW_WIDTH}
            position="relative"
            top="2px"
            src={flags_img}
          ></Image>

          <Divider
            className="language-divider-underlay"
            size="default"
            orientation="horizontal"
            borderRadius="10px"
            top="-3px"
            width={Constants.WINDOW_WIDTH}
          ></Divider>

          <Flex
            direction="row"
            justifyContent="space-between"
            position="relative"
            top={{
              base: "-75px",
              small: "-70px",
              medium: "-70px",
              large: "-70px",
              xl: "-70px",
              xxl: "-70px",
            }}
            width={Constants.WINDOW_WIDTH}
          >
            <Button
              className="language-button"
              onClick={() => {
                selectedLanguage = "english";
                setLanguage("english");
              }}
            ></Button>

            <Button
              className="language-button"
              onClick={() => {
                selectedLanguage = "german";
                setLanguage("german");
              }}
            ></Button>

            <Button
              className="language-button"
              onClick={() => {
                selectedLanguage = "italian";
                setLanguage("italian");
              }}
            ></Button>

            <Button
              className="language-button"
              onClick={() => {
                selectedLanguage = "spanish";
                setLanguage("spanish");
              }}
            ></Button>

            <Button
              className="language-button"
              onClick={() => {
                selectedLanguage = "french";
                setLanguage("french");
              }}
            ></Button>
          </Flex>
        </Flex>
        <Flex
          direction="row"
          justifyContent="center"
          position="relative"
          top="-86px"
          left={languageBarPosition[selectedLanguage]}
        >
          <Divider
            className="language-divider"
            orientation="horizontal"
            borderRadius="10px"
            width="38px"
            top="18px"
          ></Divider>
        </Flex>

        <Flex
          direction="column"
          position="relative"
          top="-70px"
          gap="0px"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          {isAlertVisible ? (
            <Alert
              display="flex"
              variation="error"
              position="relative"
              width={Constants.WINDOW_WIDTH}
              justifyContent="center"
              alignItems="center"
              borderRadius="7px"
              children={textBase["loginPage"][selectedLanguage][isAlertVisible]} //here the alert is displayed: e.g. "Attention! Have you scanned the QR Code?"
              isDismissible={false}
              hasIcon={true}
            ></Alert>
          ) : null}

          {isInfoAlertVisible ? (
            <Alert
              className="info-alert"
              variation="info"
              borderRadius="7px"
              width={Constants.WINDOW_WIDTH}
              children={textBase["loginPage"][selectedLanguage]["contactMsg"]} //here you get the contact information (Email address)
            ></Alert>
          ) : null}

          <Text
            className="text-element"
            width={Constants.WINDOW_WIDTH}
            children={textBase["loginPage"][selectedLanguage]["usernameLabel"]}
          ></Text>
          <TextField
            className="text-field"
            width={Constants.WINDOW_WIDTH}
            placeholder={userName}
            size="small"
            onChange={(event) => {
              setUserName(event.target.value);
            }}
          ></TextField>

          <Text
            className="text-element"
            width={Constants.WINDOW_WIDTH}
            children={textBase["loginPage"][selectedLanguage]["passwordLabel"]}
          ></Text>
          <PasswordField
            className="password-field"
            width={Constants.WINDOW_WIDTH}
            placeholder={userPass}
            size="small"
            hideShowPassword={false}
            onChange={(event) => {
              setUserPass(event.target.value);
            }}
          ></PasswordField>

          <Link
            className="text-element"
            width={Constants.WINDOW_WIDTH}
            paddingTop="10px"
            color="#ffa500"
            children={textBase["loginPage"][selectedLanguage]["forgotPassword"]}
            onClick={() => {
              setIsInfoAlertVisible(!isInfoAlertVisible); //info popup with email address
            }}
          ></Link>
          <Button
            className="commit-button"
            width={Constants.WINDOW_WIDTH}
            top="20px"
            children={textBase["loginPage"][selectedLanguage]["signInLabel"]}
            onClick={() => {
              signIn();
            }}
          ></Button>
        </Flex>

        <Flex
          direction="column"
          justifyContent="center"
          gap="6px"
          alignItems="center"
          display="flex"
        >
          <Divider
            label=""
            orientation="horizontal"
            width={Constants.WINDOW_WIDTH}
          ></Divider>
          <Button
            className="imprint-button"
            size="small"
            children={textBase["legal"][selectedLanguage]["imprint"][0]}
            width={Constants.WINDOW_WIDTH}
            onClick={() => {
              setIsImprintVisible(!isImprintVisible); //activates (=shows) the imprint
              setIsDataPolicyVisible(false); //hides the other data policy panel
              setIsInfoAlertVisible(false); //hides the other info alert panel
            }}
          ></Button>

          <Button
            className="imprint-button"
            size="small"
            children={textBase["legal"][selectedLanguage]["copyright"][0]}
            width={Constants.WINDOW_WIDTH}
            onClick={() => {
              setIsDataPolicyVisible(!isDataPolicyVisible); //see above - update: instead of Datapolicy we only need the copyright information
              setIsImprintVisible(false); //see above
              setIsInfoAlertVisible(false); //see above
            }}
          ></Button>

          <Button
            className="imprint-button"
            size="small"
            children={textBase["legal"][selectedLanguage]["contact"][0]}
            width={Constants.WINDOW_WIDTH}
            onClick={() => {
              setIsInfoAlertVisible(!isInfoAlertVisible); //see above
              setIsImprintVisible(false); //see above
              setIsDataPolicyVisible(false); //see above
            }}
          ></Button>
        </Flex>

        <Flex
          direction="column"
          position="relative"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          {isImprintVisible ? (
            <Alert
              className="info-alert"
              width={Constants.WINDOW_WIDTH}
              heading={textBase["legal"][selectedLanguage]["imprint"][0]}
              //top="-25px"
            >
              <p>{textBase["legal"][selectedLanguage]["imprint"][1]}</p>
              {textBase["legal"][selectedLanguage]["imprint"][2]}
              <p></p>
              {textBase["legal"][selectedLanguage]["imprint"][3]}
              <p></p>
              {textBase["legal"][selectedLanguage]["imprint"][4]}
              <p></p>
              {textBase["legal"][selectedLanguage]["imprint"][5]}
              <p></p>
              {textBase["legal"][selectedLanguage]["imprint"][6]}
            </Alert>
          ) : null}

          {isDataPolicyVisible ? (
            <Alert
              className="info-alert"
              width={Constants.WINDOW_WIDTH}
              heading={textBase["legal"][selectedLanguage]["copyright"][0]}
            >
              <Markdown children={dataPolicy} remarkPlugins={[remarkGfm]} />
            </Alert>
          ) : null}
        </Flex>
      </Flex>
    </View>
  );
}
