import "./App.css";
import { BrowserRouter } from "react-router-dom";

import "@aws-amplify/ui-react/styles.css";

function App() {
  return <BrowserRouter></BrowserRouter>;
}

export default App;
