/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getQRcode = /* GraphQL */ `
  query GetQRcode($id: ID!) {
    getQRcode(id: $id) {
      id
      qrCodeID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listQRcodes = /* GraphQL */ `
  query ListQRcodes(
    $filter: ModelQRcodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQRcodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        qrCodeID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncQRcodes = /* GraphQL */ `
  query SyncQRcodes(
    $filter: ModelQRcodeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQRcodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        qrCodeID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkshopVisit = /* GraphQL */ `
  query GetWorkshopVisit($id: ID!) {
    getWorkshopVisit(id: $id) {
      id
      obdDongleID
      workshopName
      carManufacturer
      workshopAction
      engineType
      flDotNumber
      flTireManufacturer
      flTireSportline
      flTireSize
      flInnerTD
      flCenterTD
      flOuterTD
      frDotNumber
      frTireManufacturer
      frTireSportline
      frTireSize
      frInnerTD
      frCenterTD
      rrDotNumber
      rrTireManufacturer
      country
      rrTireSize
      rrInnerTD
      rrCenterTD
      rrOuterTD
      rlDotNumber
      rlTireManufacturer
      rlTireSportline
      rlTireSize
      rlInnerTD
      rlCenterTD
      rlOuterTD
      timestamp
      individualInfoMessage
      frOuterTD
      language
      rrTireSportline
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWorkshopVisits = /* GraphQL */ `
  query ListWorkshopVisits(
    $filter: ModelWorkshopVisitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkshopVisits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        obdDongleID
        workshopName
        carManufacturer
        workshopAction
        engineType
        flDotNumber
        flTireManufacturer
        flTireSportline
        flTireSize
        flInnerTD
        flCenterTD
        flOuterTD
        frDotNumber
        frTireManufacturer
        frTireSportline
        frTireSize
        frInnerTD
        frCenterTD
        rrDotNumber
        rrTireManufacturer
        country
        rrTireSize
        rrInnerTD
        rrCenterTD
        rrOuterTD
        rlDotNumber
        rlTireManufacturer
        rlTireSportline
        rlTireSize
        rlInnerTD
        rlCenterTD
        rlOuterTD
        timestamp
        individualInfoMessage
        frOuterTD
        language
        rrTireSportline
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkshopVisits = /* GraphQL */ `
  query SyncWorkshopVisits(
    $filter: ModelWorkshopVisitFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkshopVisits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        obdDongleID
        workshopName
        carManufacturer
        workshopAction
        engineType
        flDotNumber
        flTireManufacturer
        flTireSportline
        flTireSize
        flInnerTD
        flCenterTD
        flOuterTD
        frDotNumber
        frTireManufacturer
        frTireSportline
        frTireSize
        frInnerTD
        frCenterTD
        rrDotNumber
        rrTireManufacturer
        country
        rrTireSize
        rrInnerTD
        rrCenterTD
        rrOuterTD
        rlDotNumber
        rlTireManufacturer
        rlTireSportline
        rlTireSize
        rlInnerTD
        rlCenterTD
        rlOuterTD
        timestamp
        individualInfoMessage
        frOuterTD
        language
        rrTireSportline
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
