export const WINDOW_WIDTH_FULL = "auto"; //"300px";
export const WINDOW_LEFT = "5px";
export const WINDOW_WIDTH = {
  base: "90%",
  small: "300px",
  medium: "300px",
  large: "300px",
  xl: "300px",
  xxl: "300px",
};
export const ARROW_PATH =
  "M12.5757 19.895L11.4917 20.979C11.0327 21.438 10.2905 21.438 9.83643 20.979L0.344238 11.4917C-0.114746 11.0327 -0.114746 10.2905 0.344238 9.83643L9.83643 0.344238C10.2954 -0.114746 11.0376 -0.114746 11.4917 0.344238L12.5757 1.42822C13.0395 1.89209 13.0298 2.64893 12.5562 3.10303L6.67236 8.7085L20.7056 8.7085C21.355 8.7085 21.8774 9.23096 21.8774 9.88037L21.8774 11.4429C21.8774 12.0923 21.355 12.6147 20.7056 12.6147L6.67236 12.6147L12.5562 18.2202C13.0347 18.6743 13.0444 19.4312 12.5757 19.895Z";
export const INFOICON_PATH =
  "M9 5L11 5L11 7L9 7L9 5ZM9 9L11 9L11 15L9 15L9 9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z";
export const MAXUSERS = 30;
export const MAXQRCODES = 50;
export const MAILADDRESS = "ti_hs_sm_cde@continental.com";
