//____Overview________________________________________________________________________________________________________________________

/*
The TirePage is the implementation of the detail page, where the information for each tire gets entered. 
The TirePage represents a class. For each tire (front left, front right, rear left, rear right) an individual instance is generated: 

See e.g. the FrontL.js file in the pages folder: 
function FrontL() {
  return <TirePage tirePos="fl" />;
}
Here the TirePage is called with the parameter fl => based on this information the Tirepage renders correlating images (e.g. the pictogram of the car with the front left tire highlighted)
*/

//____Imports________________________________________________________________________________________________________________________

//Import AWS resources (includes components of the react framework)
import React from "react";
import { useStateMutationAction } from "@aws-amplify/ui-react/internal";
import {
  Button,
  Icon,
  Image,
  TextField,
  Text,
  View,
  Flex,
  SelectField,
  Alert,
} from "@aws-amplify/ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Analytics } from "aws-amplify";

//Import own resources: Images, predefined text-elements, CSS-styles, constants
import "./styles.css"; //some customized ui-styles
import * as Constants from "./constants";
import textBase from "../textBase.json"; //Json file includes all text elements (translated in 5 languages)
//Import images
import contiLogo_img from "../assets/images/Continental_AG_logo.svg";
import carDrawing_img_rl from "../assets/images/RL.svg"; //import all images because javascript does not allow imports with variable paths
import carDrawing_img_rr from "../assets/images/RR.svg";
import carDrawing_img_fl from "../assets/images/FL.svg";
import carDrawing_img_fr from "../assets/images/FR.svg";
//Import images for the tread depth information
import tireTDdefault from "../assets/images/tireTDdefault.svg";
import tireTDinnerImg from "../assets/images/tireTDInner.svg";
import tireTDouterImg from "../assets/images/tireTDOuter.svg";
import tireTDcenterImg from "../assets/images/tireTDCenter.svg";
import tireSizeImg from "../assets/images/tireSizeArrowCut.svg";
import DOTnumberImg from "../assets/images/infoDOTclear.svg";

//____Main________________________________________________________________________________________________________________________

Analytics.configure({ disabled: true }); //disable AWS Analytics

//Choose the correct image depending on the tire position parameter (see description at the beginning)
const carDrawingDataframe = {
  rl: carDrawing_img_rl,
  rr: carDrawing_img_rr,
  fl: carDrawing_img_fl,
  fr: carDrawing_img_fr,
};

//Eases the use of the 4 images for the tread depth input visualization => stored in an array
var tireTDimg = [
  tireTDdefault,
  tireTDinnerImg,
  tireTDcenterImg,
  tireTDouterImg,
];

var warningMsg = "";
var isOthersSelectedTireManufacturer = false; //in case the user wants to enter an individual tire manufacturer that is not listed in the dropdown menu, a new text field is rendered as a popup
var isOthersSelectedModellline = false; //in case the user wants to enter an individual tire modellline that is not listed in the dropdown menu, a new text field is rendered as a popup

//The @-keywords are used for the JS documentation (JSDoc)
/**
 * The TirePage is the implementation of the detail page, where the information for each tire gets entered. 
 * The TirePage represents kind of a class. For each tire (front left, front right, rear left, rear right) an individual instance is generated: 

 * See e.g. the FrontL.js file in the pages folder: 
 * function FrontL() {
 *     return <TirePage tirePos="fl" />;
 * }
 * Here the TirePage is called with the parameter fl => based on this information the Tirepage renders correlating images (e.g. the pictogram of the car with the front left tire highlighted)
 * @module TirePage */

/** @function */
export default function TirePage({ tirePos }) {
  //console.log(tirePos);

  //get data from main page via useLocation
  const location = useLocation();

  //Get updated dataframe
  var dataframe = location.state;
  //console.log(dataframe);

  var selectedLanguage = dataframe.main["language"];

  const [tireDOT, setTireDOT] = useStateMutationAction(
    dataframe[tirePos]["dotNumber"]
  );
  const [tireSize, setTireSize] = useStateMutationAction(
    dataframe[tirePos]["tireSize"]
  );
  const [tireManufacturer, setTireManufacturer] = useStateMutationAction(
    dataframe[tirePos]["tireManufacturer"]
  );
  const [tireModellline, setTireModellline] = useStateMutationAction(
    dataframe[tirePos]["tireModellline"]
  );
  const [tireTreadDepthInner, setTireTreadDepthInner] = useStateMutationAction(
    dataframe[tirePos]["innerTD"]
  );
  const [tireTreadDepthOuter, setTireTreadDepthOuter] = useStateMutationAction(
    dataframe[tirePos]["outerTD"]
  );
  const [tireTreadDepthCenter, setTireTreadDepthCenter] =
    useStateMutationAction(dataframe[tirePos]["centerTD"]);

  const [infoDOTimageVisibility, setInfoDOTimageVisibility] =
    useStateMutationAction(0);

  const [infoTireSizeImageVisibility, setInfoTireSizeImageVisibility] =
    useStateMutationAction(0);
  const [tireTDimgNumber, setTireTDimgNumber] = useStateMutationAction(0);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  //____General Functions________________________________________________________________________________________________________________________

  /**
   * This function updates the current entry of the "global" dictionary (dataframe).
   *
   * @name setData
   * @memberof module:TirePage
   * @param {string} value - special handling of two input fields
   *
   * @example
   *
   *     setData()
   */
  async function setData(value) {
    if (value != "tireManufacturer") {
      if (tireManufacturer[0] == " ") {
        dataframe[tirePos]["tireManufacturer"] = tireManufacturer.slice(1);
      } //slice: cut the first space (caused by the drop down implementation)
      else {
        dataframe[tirePos]["tireManufacturer"] = tireManufacturer;
      }
    }
    if (value != "tireModellline") {
      if (tireModellline[0] == " ") {
        dataframe[tirePos]["tireModellline"] = tireModellline.slice(1);
      } //slice: cut the first space (caused by the drop down implementation)
      else {
        dataframe[tirePos]["tireModellline"] = tireModellline;
      }
    }
    dataframe[tirePos]["dotNumber"] = tireDOT;
    dataframe[tirePos]["tireSize"] = tireSize;
    dataframe[tirePos]["innerTD"] = tireTreadDepthInner;
    dataframe[tirePos]["centerTD"] = tireTreadDepthCenter;
    dataframe[tirePos]["outerTD"] = tireTreadDepthOuter;
  }

  /**
   * This function validated the user input and generates alert messages for the user.
   *
   * @name checkDataInput
   * @memberof module:TirePage
   * @param {string} mode - specification of the input field
   * @param {string} value - current value of the input field
   * @return {boolean} 0: invalid user input, 1: valid user input
   *
   * @example
   *
   *     checkDataInput("treadDepthOuter", 2)
   */
  async function checkDataInput(mode, value) {
    warningMsg = "";

    if (
      (mode == "treadDepthOuter" && (value[0] == "" || value[1] == "")) ||
      ((dataframe[tirePos]["outerTD"][0] == "" ||
        dataframe[tirePos]["outerTD"][1] == "") &&
        mode != "treadDepthOuter") //the first condition checks if the current input (=value) inlcudes a char (=> used for "realtime" feedback). The second condition is used for the commit-validation (=> mode!="treadDepthOuter").
    ) {
      warningMsg = textBase["tirePage"][selectedLanguage]["error"][2]; //Outer Tread Depth Value is missing!
    }

    if (
      (mode == "treadDepthCenter" && (value[0] == "" || value[1] == "")) ||
      ((dataframe[tirePos]["centerTD"][0] == "" ||
        dataframe[tirePos]["centerTD"][1] == "") &&
        mode != "treadDepthCenter")
    ) {
      warningMsg = textBase["tirePage"][selectedLanguage]["error"][1]; //Center Tread Depth Value is missing!
    }

    if (
      (mode == "treadDepthInner" && (value[0] == "" || value[1] == "")) ||
      ((dataframe[tirePos]["innerTD"][0] == "" ||
        dataframe[tirePos]["innerTD"][1] == "") &&
        mode != "treadDepthInner")
    ) {
      warningMsg = textBase["tirePage"][selectedLanguage]["error"][0]; //Inner Tread Depth Value is missing!
    }

    //Make sure that the input char of the tread depth measurements is a number
    if (
      isNaN(dataframe[tirePos]["innerTD"][0]) ||
      isNaN(dataframe[tirePos]["innerTD"][1]) ||
      isNaN(dataframe[tirePos]["centerTD"][0]) ||
      isNaN(dataframe[tirePos]["centerTD"][1]) ||
      isNaN(dataframe[tirePos]["outerTD"][0]) ||
      isNaN(dataframe[tirePos]["outerTD"][1])
    ) {
      warningMsg = textBase["tirePage"][selectedLanguage]["error"][10]; //"Tread Depth Measurements must not contain any letters!"
    }

    //tireSize
    if (
      (mode == "tireSize" && value == "") ||
      ((dataframe[tirePos]["tireSize"][0] == "" ||
        dataframe[tirePos]["tireSize"][1] == "" ||
        dataframe[tirePos]["tireSize"][2] == "") &&
        mode != "tireSize")
    ) {
      warningMsg = textBase["tirePage"][selectedLanguage]["error"][3]; //"Tire size is missing or is incomplete!"
    }

    //Make sure that the input char of the tiresize is a number
    if (
      isNaN(dataframe[tirePos]["tireSize"][0]) ||
      isNaN(dataframe[tirePos]["tireSize"][1]) ||
      isNaN(dataframe[tirePos]["tireSize"][2])
    ) {
      warningMsg = textBase["tirePage"][selectedLanguage]["error"][11]; //"Tire Size must not contain any letters!"
    }

    //tireDOT => optional, cant guarantee that DOT number is readable
    if (
      mode == "dot" ||
      (dataframe[tirePos]["dotNumber"].join("") != "" && mode == "commit")
    ) {
      //if value is not filled => every entry = "" (emtpy string) => no number
      //if value is a number (minimum one entry)
      //if value includes chars => warning!
      //console.log(value);
      if (mode == "commit") {
        value = dataframe[tirePos]["dotNumber"].join("");
      }
      if (value != "") {
        //console.log("not empty!");
        if (isNaN(value)) {
          //console.log("DOT includes letters");
          warningMsg = textBase["tirePage"][selectedLanguage]["error"][6]; //"DOT number must not contain any letters!"
        } else {
          //console.log("DOT includes numbers");
          let numberToString = value.toString();
          if (numberToString.length < 4) {
            //console.log("Please complete the DOT number!");
            warningMsg = textBase["tirePage"][selectedLanguage]["error"][7]; //"DOT number is not complete!"
          } else {
            let currentYear = new Date().getFullYear();
            //console.log(currentYear);
            if (Number(numberToString.slice(-2)) > currentYear - 2000) {
              //slice(-2) selects the last two chars of the string => year of production
              warningMsg = textBase["tirePage"][selectedLanguage]["error"][8]; //"Please enter the correct year of production!"
            }
            if (Number(numberToString.slice(0, 2)) > 53) {
              //slice(0, 2) selects the first two chars of the string => week of production
              warningMsg = textBase["tirePage"][selectedLanguage]["error"][9]; //"Please enter the correct week of production!"
            }
          }
        }
      }
    }

    //tireSportline
    if (
      (mode == "tireModellline" && value == "") ||
      (dataframe[tirePos]["tireModellline"] == "" && mode != "tireModellline")
    ) {
      warningMsg = textBase["tirePage"][selectedLanguage]["error"][4]; //"Tire Modellline is missing!"
    }

    //tireManufacturer
    if (
      (mode == "tireManufacturer" && value == "") ||
      (dataframe[tirePos]["tireManufacturer"] == "" &&
        mode != "tireManufacturer")
    ) {
      warningMsg = textBase["tirePage"][selectedLanguage]["error"][5]; //"Tire Manufacturer is missing!"
    }
    /*} else if (hasNumber(dataframe[tirePos]["tireManufacturer"])) {
      warningMsg = "Tire maunufacturer should not include numbers!";
    }*/

    if (warningMsg.length == 0) {
      setIsAlertVisible(false); //no error message => no popup alert => input is validated
      if (mode == "commit") {
        dataframe[tirePos]["validInput"] = true; //the tire gets displayed green => input completed
        sendDataToMainpage();
      }
    } else {
      setIsAlertVisible(true); //error message => popup alert => input is not validated
      if (mode == "commit") {
        dataframe[tirePos]["validInput"] = false;
      }
      console.log(warningMsg);
    }
  }

  /** 
  The "optionsTireManufacturer" provides a dynamic list of tire manufacturers. 
  This feature allows the user to choose an already defined tire manufacturer from another tirepage again in a dropdown menu. 
  Imagine all four tires are Conti tires => the user does not have to enter the same tiremanufacturer again and again on each tirepage, but can choose Conti in the dropdown menu.
  The list extends automatically based on the given user input.
  */
  const optionsTireManufacturer = [];
  optionsTireManufacturer.push("");
  if (
    dataframe[tirePos]["tireManufacturer"] != "" &&
    !optionsTireManufacturer.includes(dataframe[tirePos]["tireManufacturer"])
  ) {
    optionsTireManufacturer.push(dataframe[tirePos]["tireManufacturer"]);
  }
  if (
    dataframe.fr["tireManufacturer"] != "" &&
    dataframe.fr["tireManufacturer"] !=
      textBase["mainPage"][selectedLanguage]["reasonForVisitingArr"][4] &&
    !optionsTireManufacturer.includes(dataframe.fr["tireManufacturer"])
  ) {
    optionsTireManufacturer.push(dataframe.fr["tireManufacturer"]);
  }
  if (
    dataframe.fl["tireManufacturer"] != "" &&
    dataframe.fl["tireManufacturer"] !=
      textBase["mainPage"][selectedLanguage]["reasonForVisitingArr"][4] &&
    !optionsTireManufacturer.includes(dataframe.fl["tireManufacturer"])
  ) {
    optionsTireManufacturer.push(dataframe.fl["tireManufacturer"]);
  }
  if (
    dataframe.rr["tireManufacturer"] != "" &&
    dataframe.rr["tireManufacturer"] !=
      textBase["mainPage"][selectedLanguage]["reasonForVisitingArr"][4] &&
    !optionsTireManufacturer.includes(dataframe.rr["tireManufacturer"])
  ) {
    optionsTireManufacturer.push(dataframe.rr["tireManufacturer"]);
  }
  optionsTireManufacturer.push(
    textBase["mainPage"][selectedLanguage]["reasonForVisitingArr"][4]
  );

  //Check  if data from other detail pages is available
  var optionsTireManufacturerVisibility = 0;
  if (
    optionsTireManufacturer.length > 2 ||
    dataframe[tirePos]["tireManufacturer"] != ""
  ) {
    optionsTireManufacturerVisibility = 1; //render the dropdown in case a tiremanufacturer from another tirepage is available.
  } else {
    optionsTireManufacturerVisibility = 0; //because no tire manufacturer exists, no dropdown is rendered.
  }

  /** 
  The "optionsTireModellline" provides a dynamic list of tire modelllines. 
  This feature allows the user to choose an already defined tire modelline from another tirepage again in a dropdown menu. 
  Imagine all four tires are Conti Sportcontact 7 tires => the user does not have to enter the same modellline again and again on each tirepage, but can choose Sportcontact 7 in the dropdown menu.
  The list extends automatically based on the given user input.
  */
  const optionsTireModellline = [];
  optionsTireModellline.push("");
  if (
    dataframe[tirePos]["tireModellline"] != "" &&
    !optionsTireModellline.includes(dataframe[tirePos]["tireModellline"])
  ) {
    optionsTireModellline.push(dataframe[tirePos]["tireModellline"]);
  }
  if (
    dataframe.fr["tireModellline"] != "" &&
    dataframe.fr["tireManufacturer"] !=
      textBase["mainPage"][selectedLanguage]["reasonForVisitingArr"][4] &&
    !optionsTireModellline.includes(dataframe.fr["tireModellline"])
  ) {
    //==Others
    optionsTireModellline.push(dataframe.fr["tireModellline"]);
  }
  if (
    dataframe.fl["tireModellline"] != "" &&
    dataframe.fl["tireManufacturer"] !=
      textBase["mainPage"][selectedLanguage]["reasonForVisitingArr"][4] &&
    !optionsTireModellline.includes(dataframe.fl["tireModellline"])
  ) {
    //==Others
    optionsTireModellline.push(dataframe.fl["tireModellline"]);
  }
  if (
    dataframe.rr["tireModellline"] != "" &&
    dataframe.rr["tireManufacturer"] !=
      textBase["mainPage"][selectedLanguage]["reasonForVisitingArr"][4] &&
    !optionsTireModellline.includes(dataframe.rr["tireModellline"])
  ) {
    //==Others
    optionsTireModellline.push(dataframe.rr["tireModellline"]);
  }
  optionsTireModellline.push(
    textBase["mainPage"][selectedLanguage]["reasonForVisitingArr"][4]
  );

  var optionsTireModelllineVisibility = 0;
  if (
    optionsTireModellline.length > 2 ||
    dataframe[tirePos]["tireModellline"] != ""
  ) {
    optionsTireModelllineVisibility = 1;
  } else {
    optionsTireModelllineVisibility = 0;
  }

  const navigate = useNavigate();
  const sendDataToMainpage = () => {
    navigate("/Main", { state: dataframe }); //send updated dataframe back to the mainpage
  };

  /**
   * This function automatically jumps to the next input field. This feature is used for example for the DOT-input field: after the user entered the first number, the cursor jumps directly to the next field => the user does not have to press the TAB key => enhanced user experience.
   *
   * @name handleChangeJumping
   * @memberof module:TirePage
   * @param {string} e - event of the user
   *
   * @example
   *
   *     handleChangeJumping(event)
   */
  async function handleChangeJumping(e) {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    let fieldIntIndex = parseInt(fieldIndex, 10);

    // Check if no of char in field == maxlength
    if (value.length >= maxLength) {
      // It should not be last input field
      if (fieldIntIndex < 15) {
        // Get the next input field using its name
        const nextfield = document.querySelector(
          `input[name=field-${fieldIntIndex + 1}]`
        ); //+1 selects the next input field
        //console.log(`input[name=field-${fieldIntIndex + 1}]`);

        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus(); //focus on the next (+1) input field
        }
      }
    }
  }

  //Track the back button of the browser (instead of using the implemented "navigate-back-button")
  window.onpopstate = (e) => {
    setData();
    sendDataToMainpage();
  };

  //____User Interface________________________________________________________________________________________________________________________

  return (
    <View
      width={Constants.WINDOW_WIDTH_FULL}
      left={Constants.WINDOW_LEFT}
      height="auto"
      overflow="hidden"
      position="relative"
      backgroundColor="rgba(255,255,255,1)"
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <Flex direction="column" gap="0px">
        <Flex
          direction="row"
          position="relative"
          justifyContent="space-between"
          top="0px"
        >
          <Image
            width="202px"
            height="62px"
            position="relative"
            direction="column"
            display="flex"
            left="5%"
            src={contiLogo_img} //Continental Logo
          ></Image>

          <Image
            width="50px"
            position="relative"
            direction="column"
            display="flex"
            top="15px"
            left="-5%"
            src={carDrawingDataframe[tirePos]} //Abstract car drawing that highlights the current tire
          ></Image>
        </Flex>
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          display="flex"
          gap="0px"
        >
          <Icon
            width="22px"
            height="22"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 22,
              height: 22,
            }}
            paths={[
              {
                d: Constants.ARROW_PATH, //Coordinate path for "back"-Arrow-Button
                fill: "rgba(0,0,0,1)",
                fillRule: "nonzero",
              },
            ]}
            position="relative"
            top="-30px"
            onClick={() => {
              setData();
              sendDataToMainpage();
            }}
            left="-42%"
          ></Icon>

          <Text
            className="text-element"
            width={Constants.WINDOW_WIDTH}
            children={
              textBase["tirePage"][selectedLanguage]["tireManufacturerLabel"]
            }
          ></Text>
        </Flex>

        {optionsTireManufacturerVisibility ? ( //data from other detail pages available => probably the same Company (optionsTireManufacturerVisibility = 1)
          <Flex
            direction="column"
            gap="0px"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <SelectField
              className="select-field"
              width={Constants.WINDOW_WIDTH}
              size="small"
              value={
                isOthersSelectedTireManufacturer
                  ? textBase["mainPage"][selectedLanguage][
                      "reasonForVisitingArr"
                    ][4] //="Others"
                  : tireManufacturer
              }
              options={optionsTireManufacturer} //"dynamic" list, depends on the input of the other tire-pages
              onChange={(event) => {
                if (
                  event.target.value ==
                  textBase["mainPage"][selectedLanguage][
                    "reasonForVisitingArr"
                  ][4]
                ) {
                  //="Others") {
                  isOthersSelectedTireManufacturer = true;
                  setTireManufacturer(" ");
                } else {
                  isOthersSelectedTireManufacturer = false;
                  if (event.target.value == "") {
                    setTireManufacturer("");
                  } else {
                    setTireManufacturer(event.target.value);
                  }
                }
                setData("tireManufacturer");
                checkDataInput("tireManufacturer", event.target.value);
              }}
            ></SelectField>
          </Flex>
        ) : null}

        {!optionsTireManufacturerVisibility ? ( //no data available => first editing of detail page (optionsTireManufacturerVisibility = 0)
          <Flex
            direction="column"
            gap="0px"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <TextField
              className="text-field"
              width={Constants.WINDOW_WIDTH}
              size="small"
              defaultValue={
                isOthersSelectedTireManufacturer
                  ? textBase["mainPage"][selectedLanguage][
                      "reasonForVisitingArr"
                    ][4] //="Others"
                  : tireManufacturer
              }
              onChange={(event) => {
                isOthersSelectedTireManufacturer = false;
                if (event.target.value == "") {
                  setTireManufacturer(""); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
                } else {
                  setTireManufacturer(event.target.value);
                }
                setData("tireManufacturer");
                checkDataInput("tireManufacturer", event.target.value);
              }}
            ></TextField>
          </Flex>
        ) : null}

        {isOthersSelectedTireManufacturer ? ( //if the tire manufacturer is not listed, free text field for filling in the manufacturer
          <Flex
            direction="column"
            gap="0px"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <TextField
              className="text-field"
              width={Constants.WINDOW_WIDTH}
              size="small"
              defaultValue={tireManufacturer} //{location.state[tirePos]["tireManufacturer"]}
              onChange={(event) => {
                if (event.target.value == "") {
                  setTireManufacturer(""); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
                } else {
                  setTireManufacturer(event.target.value);
                }
                setData("tireManufacturer");
                checkDataInput("tireManufacturer", event.target.value);
              }}
            ></TextField>
          </Flex>
        ) : null}
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          display="flex"
          gap="0px"
        >
          <Text
            className="text-element"
            //paddingTop="10px" //overwrite CSS style (padding-block-start => CSS variable for paddingTop), because the padding should be smaller for the popup of the "Others"-textfield
            width={Constants.WINDOW_WIDTH}
            children={
              textBase["tirePage"][selectedLanguage]["tireModelllineLabel"]
            }
          ></Text>
        </Flex>

        {optionsTireModelllineVisibility ? ( //data from other detail pages available => probably the same Company (optionsTireManufacturerVisibility = 1)
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <SelectField
              className="select-field"
              width={Constants.WINDOW_WIDTH}
              size="small"
              value={
                isOthersSelectedModellline
                  ? textBase["mainPage"][selectedLanguage][
                      "reasonForVisitingArr"
                    ][4] //="Others"
                  : tireModellline
              }
              options={optionsTireModellline}
              onChange={(event) => {
                if (
                  event.target.value ==
                  textBase["mainPage"][selectedLanguage][
                    "reasonForVisitingArr"
                  ][4]
                ) {
                  isOthersSelectedModellline = true;
                  setTireModellline(" ");
                } else {
                  isOthersSelectedModellline = false;
                  if (event.target.value == "") {
                    setTireModellline("");
                  } else {
                    setTireModellline(event.target.value);
                  }
                }
                setData("tireModellline");
                checkDataInput("tireModellline", event.target.value);
              }}
            ></SelectField>
          </Flex>
        ) : null}

        {!optionsTireModelllineVisibility ? ( //no data available => first editing of detail page (optionsTireManufacturerVisibility = 0)
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <TextField
              className="text-field"
              width={Constants.WINDOW_WIDTH}
              size="small"
              defaultValue={tireModellline}
              onChange={(event) => {
                if (event.target.value == "") {
                  setTireModellline(""); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
                } else {
                  setTireModellline(event.target.value);
                }
                setData("tireModellline");
                checkDataInput("tireModellline", event.target.value);
              }}
            ></TextField>
          </Flex>
        ) : null}

        {isOthersSelectedModellline ? ( //if the tire manufacturer is not listed, free text field for filling in the manufacturer
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <TextField
              className="text-field"
              width={Constants.WINDOW_WIDTH}
              size="small"
              defaultValue={tireModellline} //{location.state[tirePos]["tireManufacturer"]}
              onChange={(event) => {
                if (event.target.value == "") {
                  setTireModellline(""); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
                } else {
                  setTireModellline(event.target.value);
                }
                setData("tireModellline");
                checkDataInput("tireModellline", event.target.value);
              }}
            ></TextField>
          </Flex>
        ) : null}
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          display="flex"
          gap="0px"
        >
          <Text
            className="text-element"
            width={Constants.WINDOW_WIDTH}
            children={textBase["tirePage"][selectedLanguage]["tireDOTLabel"]}
          ></Text>

          <Flex
            direction="row"
            position="relative"
            justifyContent="center"
            width={Constants.WINDOW_WIDTH}
          >
            <TextField
              className="text-field-DOT"
              maxLength="1"
              defaultValue={location.state[tirePos]["dotNumber"][0]}
              size="small"
              name="field-1"
              length="1"
              onChange={(event) => {
                if (event.target.value == "") {
                  setTireDOT(["", tireDOT[1], tireDOT[2], tireDOT[3]]); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
                } else {
                  setTireDOT([
                    event.target.value,
                    tireDOT[1],
                    tireDOT[2],
                    tireDOT[3],
                  ]);
                }
                setData();
                handleChangeJumping(event); //automatically jumps to next input cell => enhances user experience

                checkDataInput(
                  "dot",
                  event.target.value + tireDOT[1] + tireDOT[2] + tireDOT[3]
                );
              }}
            ></TextField>

            <TextField
              className="text-field-DOT"
              maxLength="1"
              defaultValue={location.state[tirePos]["dotNumber"][1]}
              size="small"
              name="field-2"
              length="1"
              onChange={(event) => {
                if (event.target.value == "") {
                  setTireDOT([tireDOT[0], "", tireDOT[2], tireDOT[3]]); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
                } else {
                  setTireDOT([
                    tireDOT[0],
                    event.target.value,
                    tireDOT[2],
                    tireDOT[3],
                  ]);
                }
                setData();
                handleChangeJumping(event); //automatically jumps to next input cell => enhances user experience

                checkDataInput(
                  "dot",
                  tireDOT[0] + event.target.value + tireDOT[2] + tireDOT[3]
                );
              }}
            ></TextField>

            <TextField
              className="text-field-DOT"
              maxLength="1"
              defaultValue={location.state[tirePos]["dotNumber"][2]}
              size="small"
              name="field-3"
              length="1"
              onChange={(event) => {
                if (event.target.value == "") {
                  setTireDOT([tireDOT[0], tireDOT[1], "", tireDOT[3]]); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
                } else {
                  setTireDOT([
                    tireDOT[0],
                    tireDOT[1],
                    event.target.value,
                    tireDOT[3],
                  ]);
                }
                setData();
                handleChangeJumping(event); //automatically jumps to next input cell => enhances user experience

                checkDataInput(
                  "dot",
                  tireDOT[0] + tireDOT[1] + event.target.value + tireDOT[3]
                );
              }}
            ></TextField>

            <TextField
              className="text-field-DOT"
              maxLength="1"
              defaultValue={location.state[tirePos]["dotNumber"][3]}
              size="small"
              name="field-4"
              length="1"
              onChange={(event) => {
                if (event.target.value == "") {
                  setTireDOT([tireDOT[0], tireDOT[1], tireDOT[2], ""]); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
                } else {
                  setTireDOT([
                    tireDOT[0],
                    tireDOT[1],
                    tireDOT[2],
                    event.target.value,
                  ]);
                }
                let threeChars = tireDOT.slice(0, 3);
                checkDataInput("dot", threeChars.join("") + event.target.value);
              }}
            ></TextField>

            <Icon
              width="30px"
              position="relative"
              top="15px"
              height="25px"
              paths={[
                {
                  d: Constants.INFOICON_PATH,
                  fill: "rgba(13,26,38,1)",
                  fillRule: "nonzero",
                },
              ]}
              onClick={() => {
                setData();
                setInfoDOTimageVisibility(!infoDOTimageVisibility);
              }}
            ></Icon>
          </Flex>

          <Flex direction="column" position="relative">
            {infoDOTimageVisibility ? ( //data from other detail pages available => probably the same Company
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Image
                  height="200px"
                  width="300px"
                  left="-10px"
                  position="relative"
                  src={DOTnumberImg}
                ></Image>
                <Alert
                  className="info-alert"
                  variation="info"
                  backgroundColor="lightgrey"
                  width={Constants.WINDOW_WIDTH}
                >
                  {textBase["tirePage"][selectedLanguage]["infoAboutDOTnumber"]}
                </Alert>
              </Flex>
            ) : null}
          </Flex>

          <Flex direction="column" gap="0px" width={Constants.WINDOW_WIDTH}>
            <Text
              className="text-element"
              width={Constants.WINDOW_WIDTH}
              children={textBase["tirePage"][selectedLanguage]["tireSizeLabel"]}
            ></Text>
            <Flex
              direction="row"
              position="relative"
              justifyContent="center"
              top="-5px"
            >
              <TextField
                className="text-field"
                descriptiveText={
                  textBase["tirePage"][selectedLanguage][
                    "tireSizeTextElements"
                  ][0]
                }
                textAlign="center"
                maxLength="3"
                direction="column"
                defaultValue={location.state[tirePos]["tireSize"][0]}
                size="small"
                onChange={(event) => {
                  if (event.target.value == "") {
                    setTireSize(["", tireSize[1], tireSize[2]]);
                  } else {
                    setTireSize([event.target.value, tireSize[1], tireSize[2]]); //tiresize is divided into three parts: Width, Aspect Ratio, Rim Size => here the width is entered
                  }
                  setData();
                  checkDataInput("tireSize", event.target.value); //directly validate input to get a "realtime" feedback
                }}
              ></TextField>

              <TextField
                className="text-field"
                descriptiveText={
                  textBase["tirePage"][selectedLanguage][
                    "tireSizeTextElements"
                  ][1]
                }
                textAlign="center"
                maxLength="3"
                direction="column"
                defaultValue={location.state[tirePos]["tireSize"][1]}
                size="small"
                onChange={(event) => {
                  if (event.target.value == "") {
                    setTireSize([tireSize[0], "", tireSize[2]]);
                  } else {
                    setTireSize([tireSize[0], event.target.value, tireSize[2]]); //tiresize is divided into three parts: Width, Aspect Ratio, Rim Size => here the aspect ration is entered
                  }
                  setData();
                  checkDataInput("tireSize", event.target.value);
                }}
              ></TextField>

              <TextField
                className="text-field"
                descriptiveText={
                  textBase["tirePage"][selectedLanguage][
                    "tireSizeTextElements"
                  ][2]
                }
                textAlign="center"
                maxLength="3"
                direction="column"
                defaultValue={location.state[tirePos]["tireSize"][2]}
                size="small"
                onChange={(event) => {
                  if (event.target.value == "") {
                    setTireSize([tireSize[0], tireSize[1], ""]);
                  } else {
                    setTireSize([tireSize[0], tireSize[1], event.target.value]); //tiresize is divided into three parts: Width, Aspect Ratio, Rim Size => here the rim size is entered
                  }
                  setData();
                  checkDataInput("tireSize", event.target.value);
                }}
              ></TextField>

              <Icon
                width="60px"
                position="relative"
                top="40px"
                height="25px"
                paths={[
                  {
                    d: Constants.INFOICON_PATH,
                    fill: "rgba(13,26,38,1)",
                    fillRule: "nonzero",
                  },
                ]}
                onClick={() => {
                  setData();
                  setInfoTireSizeImageVisibility(!infoTireSizeImageVisibility);
                }}
              ></Icon>
            </Flex>

            <Flex direction="column" gap="0px">
              {infoTireSizeImageVisibility ? ( //data from other detail pages available => probably the same Company
                <Flex
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                >
                  <Image
                    width={Constants.WINDOW_WIDTH}
                    left="-10px"
                    position="relative"
                    src={tireSizeImg}
                  ></Image>
                </Flex>
              ) : null}
            </Flex>

            <Text
              className="text-element"
              width={Constants.WINDOW_WIDTH}
              children={textBase["tirePage"][selectedLanguage]["tireTDtext"]}
            ></Text>
            <Flex
              direction="row"
              position="relative"
              justifyContent="flex-start"
            >
              <Flex
                direction="column"
                position="relative"
                justifyContent="flex-start"
                top="2px"
              >
                <Flex
                  direction="row"
                  position="relative"
                  justifyContent="left"
                  top="-5px"
                >
                  <TextField
                    className="text-field-tread-depth"
                    descriptiveText={
                      textBase["tirePage"][selectedLanguage]["tireTDInner"]
                    }
                    maxLength="2"
                    defaultValue={location.state[tirePos]["innerTD"][0]}
                    size="small"
                    onChange={(event) => {
                      if (event.target.value == "") {
                        setTireTreadDepthInner(["", tireTreadDepthInner[1]]);
                        checkDataInput("treadDepthInner", [
                          "",
                          tireTreadDepthInner[1],
                        ]);
                      } else {
                        setTireTreadDepthInner([
                          event.target.value,
                          tireTreadDepthInner[1],
                        ]);
                        checkDataInput("treadDepthInner", [
                          event.target.value,
                          tireTreadDepthInner[1],
                        ]);
                      }
                    }}
                    onSelect={(event) => {
                      setTireTDimgNumber(1); //innerTD
                      setData();
                    }}
                  ></TextField>

                  <Text className="text-element-comma" children=","></Text>

                  <TextField
                    className="text-field-tread-depth"
                    top="25px"
                    left="-28px"
                    maxLength="1"
                    defaultValue={location.state[tirePos]["innerTD"][1]}
                    size="small"
                    name="field-10"
                    length="1"
                    onChange={(event) => {
                      if (event.target.value == "") {
                        setTireTreadDepthInner([tireTreadDepthInner[0], ""]);
                        checkDataInput("treadDepthInner", [
                          tireTreadDepthInner[0],
                          "",
                        ]);
                      } else {
                        setTireTreadDepthInner([
                          tireTreadDepthInner[0],
                          event.target.value,
                        ]);
                        checkDataInput("treadDepthInner", [
                          tireTreadDepthInner[0],
                          event.target.value,
                        ]);
                      }
                      handleChangeJumping(event);
                    }}
                    onSelect={(event) => {
                      setTireTDimgNumber(1); //innerTD
                      setData();
                    }}
                  ></TextField>
                </Flex>
                <Flex
                  direction="row"
                  position="relative"
                  justifyContent="left"
                  top="-5px"
                >
                  <TextField
                    className="text-field-tread-depth"
                    descriptiveText={
                      textBase["tirePage"][selectedLanguage]["tireTDCenter"]
                    }
                    maxLength="2"
                    defaultValue={location.state[tirePos]["centerTD"][0]}
                    size="small"
                    name="field-11"
                    length="2"
                    onChange={(event) => {
                      if (event.target.value == "") {
                        setTireTreadDepthCenter(["", tireTreadDepthCenter[1]]);
                        checkDataInput("treadDepthCenter", [
                          "",
                          tireTreadDepthCenter[1],
                        ]);
                      } else {
                        setTireTreadDepthCenter([
                          event.target.value,
                          tireTreadDepthCenter[1],
                        ]);
                        checkDataInput("treadDepthCenter", [
                          event.target.value,
                          tireTreadDepthCenter[1],
                        ]);
                      }
                      //NO handleChangeJumping(event), because the profile detph can be one or two digits => unknown length! => user needs to switch manually to the next input field
                    }}
                    onSelect={(event) => {
                      setTireTDimgNumber(2); //centerTD
                      setData();
                    }}
                  ></TextField>

                  <Text className="text-element-comma" children=","></Text>

                  <TextField
                    className="text-field-tread-depth"
                    top="25px"
                    left="-28px"
                    maxLength="1"
                    defaultValue={location.state[tirePos]["centerTD"][1]}
                    size="small"
                    name="field-12"
                    length="1"
                    onChange={(event) => {
                      if (event.target.value == "") {
                        setTireTreadDepthCenter([tireTreadDepthCenter[0], ""]);
                        checkDataInput("treadDepthCenter", [
                          tireTreadDepthCenter[0],
                          "",
                        ]);
                      } else {
                        setTireTreadDepthCenter([
                          tireTreadDepthCenter[0],
                          event.target.value,
                        ]);
                        checkDataInput("treadDepthCenter", [
                          tireTreadDepthCenter[0],
                          event.target.value,
                        ]);
                      }
                      handleChangeJumping(event);
                    }}
                    onSelect={(event) => {
                      setTireTDimgNumber(2); //centerTD
                      setData();
                    }}
                  ></TextField>
                </Flex>

                <Flex
                  direction="row"
                  position="relative"
                  justifyContent="left"
                  top="-5px"
                >
                  <TextField
                    className="text-field-tread-depth"
                    descriptiveText={
                      textBase["tirePage"][selectedLanguage]["tireTDOuter"]
                    }
                    maxLength="2"
                    defaultValue={location.state[tirePos]["outerTD"][0]}
                    size="small"
                    name="field-13"
                    length="2"
                    onChange={(event) => {
                      if (event.target.value == "") {
                        setTireTreadDepthOuter(["", tireTreadDepthOuter[1]]);
                        checkDataInput("treadDepthOuter", [
                          "",
                          tireTreadDepthOuter[1],
                        ]);
                      } else {
                        setTireTreadDepthOuter([
                          event.target.value,
                          tireTreadDepthOuter[1],
                        ]);
                        checkDataInput("treadDepthOuter", [
                          event.target.value,
                          tireTreadDepthOuter[1],
                        ]);
                      }
                      //NO handleChangeJumping(event), because the profile detph can be one or two digits => unknown length! => user needs to switch manually to the next input field
                    }}
                    onSelect={(event) => {
                      setTireTDimgNumber(3); //outerTD
                      setData();
                    }}
                  ></TextField>

                  <Text className="text-element-comma" children=","></Text>

                  <TextField
                    className="text-field-tread-depth"
                    top="25px"
                    left="-28px"
                    maxLength="1"
                    defaultValue={location.state[tirePos]["outerTD"][1]}
                    size="small"
                    onChange={(event) => {
                      if (event.target.value == "") {
                        setTireTreadDepthOuter([tireTreadDepthOuter[0], ""]);
                        checkDataInput("treadDepthOuter", [
                          tireTreadDepthOuter[0],
                          "",
                        ]);
                      } else {
                        setTireTreadDepthOuter([
                          tireTreadDepthOuter[0],
                          event.target.value,
                        ]);
                        checkDataInput("treadDepthOuter", [
                          tireTreadDepthOuter[0],
                          event.target.value,
                        ]);
                      }
                    }}
                    onSelect={(event) => {
                      setTireTDimgNumber(3); //outerTD
                      setData();
                    }}
                  ></TextField>
                </Flex>
              </Flex>
              <Image
                height="240px"
                width="200px"
                left="-10px"
                position="relative"
                src={tireTDimg[tireTDimgNumber]}
              ></Image>
            </Flex>
          </Flex>
          {isAlertVisible ? (
            <Alert
              className="info-alert"
              display="flex"
              variation="error"
              position="relative"
              width={Constants.WINDOW_WIDTH}
              justifyContent="center"
              alignItems="center"
              children={warningMsg} //shows the in "checkDataInput" generated alert message as an popup element at the end of the page
              isDismissible={false}
              hasIcon={true}
            ></Alert>
          ) : null}
          <Button
            className="commit-button"
            size="small"
            width={Constants.WINDOW_WIDTH}
            top="10px"
            children={textBase["tirePage"][selectedLanguage]["commitButton"]}
            onClick={() => {
              setData();
              checkDataInput("commit");
            }}
          ></Button>
          <Text
            className="text-element"
            width={Constants.WINDOW_WIDTH}
            children=""
          ></Text>
        </Flex>
      </Flex>
    </View>
  );
}
