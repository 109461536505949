//____Overview________________________________________________________________________________________________________________________

/*
The Main page provides a couple of functions. 
After the login the user gets redirected to this page. In the first step it is used to get the general information about the workshop and about the car (e.g. name of the workshop, country, reason for visiting the workshop, engine type...).
In a next step there is an image of an abstract car displaying 4 tires (these tires are represented by buttons). If you click on one of those buttons (=tires) you get redirected to the individual tire page (e.g. front left) for entering the tire related data.
Is every datafield filled out accordingly (=without warning) the user needs to confirm the input via the commit button at the end of the page. By clicking on the commit button the data transfer to the AWS Dynamo DB is triggered. 
Based on the given information from the main page and the 4 tires, that is stored in a "global" dictionary, a dataentry gets created. You can identify each dataentry by its timestamp and a unique AWS ID. In context of the TDM webapp the most
important part is the QR code ID. This QR code ID is matched to the serial number of the corresponding OBD dongle - this matching list is stored internally at Continental.
*/

//____Imports________________________________________________________________________________________________________________________

//Import AWS resources (includes components of the react framework)
import React from "react";
import {
  useNavigateAction,
  useStateMutationAction,
} from "@aws-amplify/ui-react/internal";
import { DataStore } from "@aws-amplify/datastore";
import { WorkshopVisit } from "../models";
import { Amplify, Auth, API } from "aws-amplify";
import {
  Button,
  Image,
  SelectField,
  TextField,
  View,
  Alert,
  Text,
  Flex,
  Divider,
} from "@aws-amplify/ui-react";
import { Analytics } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//Import own resources: Images, predefined text-elements, CSS-styles, constants
import contiLogo_img from "../assets/images/Continental_AG_logo.svg";
import carDrawing_img from "../assets/images/abstractCarDesignLED.svg";
import flags_img from "../assets/images/flagsCenteredWide.svg";

import "./styles.css";
import * as Constants from "./constants";
import textBase from "../textBase.json"; //Json file includes all text elements (translated in 5 languages)
import datapolicyDoc from "../assets/legal/copyright.md"; //exchange data policy with the copyright information

//Functions to interpret markdown documents
import Markdown from "react-markdown"; //for rendering markdown (data policy is provided in form of a markdown file)
import remarkGfm from "remark-gfm";

//____Main________________________________________________________________________________________________________________________

Analytics.configure({ disabled: true }); //disable AWS Analytics

var timestamp = new Date().toISOString(); // store the current timestamp in a string format
//console.log("Current Timestamp: " + timestamp);

//Define colors for the "tire"-buttons
var flTireColorMouseEnter = "#FFA500";
var flTireColorMouseLeave = "#AEB3B7";
var flbuttonCol = "rgba(174,179,183,1)";
var frTireColorMouseEnter = "#FFA500";
var frTireColorMouseLeave = "#AEB3B7";
var frbuttonCol = "rgba(174,179,183,1)";
var rrTireColorMouseEnter = "#FFA500";
var rrTireColorMouseLeave = "#AEB3B7";
var rrbuttonCol = "rgba(174,179,183,1)";
var rlTireColorMouseEnter = "#FFA500";
var rlTireColorMouseLeave = "#AEB3B7";
var rlbuttonCol = "rgba(174,179,183,1)";

//In case the user cannot find the corresponding dataentry in the predefined dropdown menu, the user can enter the data in an individual textfield => this textfield pops up, when the specific "isOthersSelectedxxx" is true
var isOthersSelectedreasonForVisiting = false;
var isOthersSelectedCarManuf = false;
var isOthersSelectedEngineType = false;

var selectedLanguage = "english"; //Default language

var warningMsg = "";
var loaded = 1; //indicates if the page is loaded or not, used for a oneway-function call - execute a function once at the beginning

//The @-keywords are used for the JS documentation (JSDoc)
/**
 * The Main page provides a couple of functions.
 * After the login the user gets redirected to this page. In the first step it is used to get the general information about the workshop and about the car (e.g. name of the workshop, country, reason for visiting the workshop, engine type...).
 * In a next step there is an image of an abstract car displaying 4 tires (these tires are represented by buttons). If you click on one of those buttons (=tires) you get redirected to the individual tire page (e.g. front left) for entering the tire related data.
 * Is every datafield filled out accordingly (=without warning) the user needs to confirm the input via the commit button at the end of the page. By clicking on the commit button the data transfer to the AWS Dynamo DB is triggered.
 * Based on the given information from the main page and the 4 tires, that is stored in a "global" dictionary, a dataentry gets created. You can identify each dataentry by its timestamp and a unique AWS ID. In context of the TDM webapp the most
 * important part is the QR code ID. This QR code ID is matched to the serial number of the corresponding OBD dongle - this matching list is stored internally at Continental.
 * @module Mainpage */

/** @function */
export default function Main() {
  const navigate = useNavigate(); //for navigation between multiple pages

  //Get data (the "global" dictionary) from the login page via useLocation
  const location = useLocation();
  var dataframe = location.state;

  //Set the pre-selected language once
  if (loaded) {
    selectedLanguage = dataframe.main["language"]; //otherwise the language setting is overwritten all the time by the last dataframe value
    loaded = 0; //executed once
  }

  //Navigates to the adminpage - triggered by a button click
  const adminNav = useNavigateAction({
    type: "url",
    url: "/TDMwebAppAdmin",
  });

  //Navigates to the detail tire pages: front left (FL), front right (FR), rear left (RL), rear right (RR) - triggered by the "tire"-buttons
  const dataToFL = () => {
    navigate("/FL", { state: dataframe });
  };
  const dataToFR = () => {
    navigate("/FR", { state: dataframe });
  };
  const dataToRL = () => {
    navigate("/RL", { state: dataframe });
  };
  const dataToRR = () => {
    navigate("/RR", { state: dataframe });
  };

  //Navigates to the endpage - triggered by the commit-button
  const dataToCommit = () => {
    navigate("/End", { state: selectedLanguage }); //the end-page does not need any more data - only the language information is needed
  };

  //Fetch data policy
  const [dataPolicy, setDataPolicy] = useState("");
  useEffect(() => {
    fetch(datapolicyDoc)
      .then((res) => res.text())
      .then((text) => setDataPolicy(text));
  });

  //Define the positioning of the flags for choosing the language
  const languageBarPosition = {
    english: {
      base: "-38%", //for responsive design
      small: "-128px",
      medium: "-128px",
      large: "-128px",
      xl: "-128px",
      xxl: "-128px",
    },
    german: {
      base: "-19%",
      small: "-64px",
      medium: "-64px",
      large: "-64px",
      xl: "-64px",
      xxl: "-64px",
    },
    italian: {
      base: "0%",
      small: "-2px",
      medium: "-2px",
      large: "-2px",
      xl: "-2px",
      xxl: "-2px",
    },
    spanish: {
      base: "19%",
      small: "62px",
      medium: "62px",
      large: "62px",
      xl: "62px",
      xxl: "62px",
    },
    french: {
      base: "38%",
      small: "125px",
      medium: "125px",
      large: "125px",
      xl: "125px",
      xxl: "125px",
    },
  };

  //Set the values of the input fields based on the current values of the dataframe (makes sure that the data is still displayed e.g. after coming back from a tirepage to the mainpage)
  const [country, setCountry] = useStateMutationAction(
    dataframe.main["country"]
  );
  const [workshopName, setWorkshopName] = useStateMutationAction(
    dataframe.main["workshopName"]
  );
  const [vehicleBrand, setVehicleBrand] = useStateMutationAction(
    dataframe.main["carManufacturer"]
  );
  const [workshopVisitReason, setworkshopVisitReason] = useStateMutationAction(
    dataframe.main["workshopAction"]
  );
  const [engineType, setEngineType] = useStateMutationAction(
    dataframe.main["engineType"]
  );
  const [language, setLanguage] = useStateMutationAction(
    dataframe.main["language"]
  );
  const [isInfoAlertVisible, setIsInfoAlertVisible] = useState(false);
  const [isDataPolicyVisible, setIsDataPolicyVisible] = useState(false);
  const [isImprintVisible, setIsImprintVisible] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [cognitoGroup, setCognitoGroup] = useState(false);

  //Define color of each tire-buttons dynamically - if the data of the corresponding tire page is valid, the tire appears green (#9eeb34) otherwise it is still orange (#FFA500)
  if (dataframe.fl["validInput"]) {
    flTireColorMouseEnter = "#9eeb34"; //Green => the user successfully entered the required tire information
    flTireColorMouseLeave = "#9eeb34";
    flbuttonCol = "rgba(158, 235, 52, 1)";
  } else {
    flTireColorMouseEnter = "#FFA500"; //Orange => there is still data missing at the tire page
    flTireColorMouseLeave = "#AEB3B7";
    flbuttonCol = "rgba(174,179,183,1)";
  }

  if (dataframe.fr["validInput"]) {
    frTireColorMouseEnter = "#9eeb34";
    frTireColorMouseLeave = "#9eeb34";
    frbuttonCol = "rgba(158, 235, 52, 1)";
  } else {
    frTireColorMouseEnter = "#FFA500";
    frTireColorMouseLeave = "#AEB3B7";
    frbuttonCol = "rgba(174,179,183,1)";
  }

  if (dataframe.rl["validInput"]) {
    rlTireColorMouseEnter = "#9eeb34";
    rlTireColorMouseLeave = "#9eeb34";
    rlbuttonCol = "rgba(158, 235, 52, 1)";
  } else {
    rlTireColorMouseEnter = "#FFA500";
    rlTireColorMouseLeave = "#AEB3B7";
    rlbuttonCol = "rgba(174,179,183,1)";
  }

  if (dataframe.rr["validInput"]) {
    rrTireColorMouseEnter = "#9eeb34";
    rrTireColorMouseLeave = "#9eeb34";
    rrbuttonCol = "rgba(158, 235, 52, 1)";
  } else {
    rrTireColorMouseEnter = "#FFA500";
    rrTireColorMouseLeave = "#AEB3B7";
    rrbuttonCol = "rgba(174,179,183,1)";
  }

  //Manage the color of the tire-buttons
  const [startBagdeFLBackgroundColor, setStartBagdeFLBackgroundColor] =
    useStateMutationAction(flbuttonCol);
  const [startBadgeFRBackgroundColor, setStartBadgeFRBackgroundColor] =
    useStateMutationAction(frbuttonCol);
  const [startBadgeRLBackgroundColor, setStartBadgeRLBackgroundColor] =
    useStateMutationAction(rlbuttonCol);
  const [startBadgeRRBackgroundColor, setStartBadgeRRBackgroundColor] =
    useStateMutationAction(rrbuttonCol);
  const startBagdeFLOnMouseEnter = () => {
    setStartBagdeFLBackgroundColor(flTireColorMouseEnter);
  };
  const startBagdeFLOnMouseLeave = () => {
    setStartBagdeFLBackgroundColor(flTireColorMouseLeave);
  };
  const startBadgeFROnMouseEnter = () => {
    setStartBadgeFRBackgroundColor(frTireColorMouseEnter);
  };
  const startBadgeFROnMouseLeave = () => {
    setStartBadgeFRBackgroundColor(frTireColorMouseLeave);
  };
  const startBadgeRLOnMouseLeave = () => {
    setStartBadgeRLBackgroundColor(rlTireColorMouseLeave);
  };
  const startBadgeRLOnMouseEnter = () => {
    setStartBadgeRLBackgroundColor(rlTireColorMouseEnter);
  };
  const startBadgeRROnMouseLeave = () => {
    setStartBadgeRRBackgroundColor(rrTireColorMouseLeave);
  };
  const startBadgeRROnMouseEnter = () => {
    setStartBadgeRRBackgroundColor(rrTireColorMouseEnter);
  };

  //____General Functions________________________________________________________________________________________________________________________

  /**
   * This function updates the current entry of the "global" dictionary (dataframe).
   *
   * @name setData
   * @memberof module:Mainpage
   * @example
   *
   *     setData()
   */
  async function setData() {
    dataframe.main["country"] = country;
    dataframe.main["workshopName"] = workshopName;
    dataframe.main["carManufacturer"] = vehicleBrand;
    dataframe.main["workshopAction"] = workshopVisitReason;
    dataframe.main["language"] = selectedLanguage;
    dataframe.main["engineType"] = engineType;
  }

  /**
   * This function checks if the input contains a number or not.
   *
   * @name hasNumber
   * @memberof module:Mainpage
   * @param {number} inputString - string
   * @return {boolean} 0: input (string) does not contain a number, 1: input (string) does contain a number
   *
   * @example
   *
   *     hasNumber("abc99")
   */
  function hasNumber(inputString) {
    return /\d/.test(inputString);
  }

  /**
   * This function sends an email to the given email address.
   * In case of the TDM webapp this function is used to notify us (=Conti) when there is a new dataentry available.
   * Therefore we are using a seperate (service) email address: ti_hs_sm_cde@continental.com
   *
   * @name sendEmail
   * @memberof module:Mainpage
   * @param {string} emailAddress - email address of the receiver
   * @return {boolean} 1: email send, 0: issues while sending the email
   *
   * @example
   *
   *     sendEmail("ti_hs_sm_cde@continental.com")
   */
  async function sendEmail(emailAddress) {
    const AWS = require("aws-sdk");
    const mycredentials = await Amplify.Auth.currentCredentials();
    const ses = new AWS.SES({
      region: "eu-central-1",
      credentials: mycredentials,
    });
    var params = {
      Destination: {
        ToAddresses: [emailAddress], //e.g. ["philipp.grube@conti.de"],
      },
      Message: {
        Body: {
          Text: {
            Data: "A new data entry has just been created!", //hardcoded and not translated, because its only for admins
          },
        },
        Subject: {
          Data: "TDM Webapp",
        },
      },
      Source: emailAddress, //e.g. "philipp.grube@conti.de",
      Tags: [
        {
          Name: "Application",
          Value: "TDMwebApp",
        },
      ],
    };
    ses.sendEmail(params, function (err, data) {
      if (err) {
        console.log(err, err.stack); // an error occurred
        return 0;
      } else {
        //console.log(data); // successful response
        return 1;
      }
    });
  }

  /**
   * This function transfers the data stored in the dictionary (dataframe) to the Dynamo DB by using the predefined AWS function DataStore.save().
   *
   * @name createDatabaseEntry
   * @memberof module:Mainpage
   * @return {boolean} 1: data pushed, 0: issues while pushing data to the DB
   *
   * @example
   *
   *     createDatabaseEntry()
   */
  async function createDatabaseEntry() {
    try {
      const createDatabaseEntry = await DataStore.save(
        new WorkshopVisit({
          obdDongleID: dataframe.main["obdID"],
          workshopName: dataframe.main["workshopName"],
          carManufacturer: hasNumber(dataframe.main["carManufacturer"])
            ? textBase["mainPage"]["carManufacturersArr"][
                dataframe.main["carManufacturer"]
              ]
            : dataframe.main["carManufacturer"], //because of the multi-language implementation, indices are used for the select-field option - the database requires a string, that is why a mapping between index and textbase entry is done, if its not a number, its a customized entry
          workshopAction: hasNumber(dataframe.main["workshopAction"])
            ? textBase["mainPage"]["english"]["reasonForVisitingArr"][
                dataframe.main["workshopAction"]
              ]
            : dataframe.main["workshopAction"],
          engineType: hasNumber(dataframe.main["engineType"])
            ? textBase["mainPage"]["english"]["engineTypeArr"][
                dataframe.main["engineType"]
              ]
            : dataframe.main["engineType"],
          flDotNumber: dataframe.fl["dotNumber"].join(""),
          flTireManufacturer: dataframe.fl["tireManufacturer"],
          flTireSportline: dataframe.fl["tireModellline"],
          flTireSize: dataframe.fl["tireSize"].toString(),
          flInnerTD: dataframe.fl["innerTD"].toString(),
          flCenterTD: dataframe.fl["centerTD"].toString(),
          flOuterTD: dataframe.fl["outerTD"].toString(),
          frDotNumber: dataframe.fr["dotNumber"].join(""),
          frTireManufacturer: dataframe.fr["tireManufacturer"],
          frTireSportline: dataframe.fr["tireModellline"],
          frTireSize: dataframe.fr["tireSize"].toString(),
          frInnerTD: dataframe.fr["innerTD"].toString(),
          frCenterTD: dataframe.fr["centerTD"].toString(),
          rrDotNumber: dataframe.rr["dotNumber"].join(""),
          rrTireManufacturer: dataframe.rr["tireManufacturer"],
          country: hasNumber(dataframe.main["country"])
            ? textBase["mainPage"][selectedLanguage]["countryArr"][
                dataframe.main["country"]
              ]
            : dataframe.main["country"],
          rrTireSize: dataframe.rr["tireSize"].toString(),
          rrInnerTD: dataframe.rr["innerTD"].toString(),
          rrCenterTD: dataframe.rr["centerTD"].toString(),
          rrOuterTD: dataframe.rr["outerTD"].toString(),
          rlDotNumber: dataframe.rl["dotNumber"].join(""),
          rlTireManufacturer: dataframe.rl["tireManufacturer"],
          rlTireSportline: dataframe.rl["tireModellline"],
          rlTireSize: dataframe.rl["tireSize"].toString(),
          rlInnerTD: dataframe.rl["innerTD"].toString(),
          rlCenterTD: dataframe.rl["centerTD"].toString(),
          rlOuterTD: dataframe.rl["outerTD"].toString(),
          timestamp: timestamp,
          individualInfoMessage: "none",
          frOuterTD: dataframe.rl["outerTD"].toString(),
          language: dataframe.main["language"],
          rrTireSportline: dataframe.rr["tireModellline"],
        })
      );
      return 1;
    } catch (error) {
      console.log("Error pushing data: ", error);
      return 0;
    }
  }

  /**
   * This function validated the user input and generates alert messages for the user.
   *
   * @name checkDataInput
   * @memberof module:Mainpage
   * @param {string} mode - specification of the input field
   * @param {string} value - current value of the input field
   *
   * @example
   *
   *     checkDataInput("engineType", "xyz")
   */
  async function checkDataInput(mode, value) {
    //check entries of all for tires (if they are green) + input of main page

    warningMsg = "";

    if (dataframe.fl["validInput"] != true) {
      warningMsg = textBase["mainPage"][selectedLanguage]["error"][0]; //"Tire data for front left is missing!"
    }
    if (dataframe.fr["validInput"] != true) {
      warningMsg = textBase["mainPage"][selectedLanguage]["error"][1]; //"Tire data for front right is missing!"
    }
    if (dataframe.rl["validInput"] != true) {
      warningMsg = textBase["mainPage"][selectedLanguage]["error"][2]; //"Tire data for rear left is missing!"
    }
    if (dataframe.rr["validInput"] != true) {
      warningMsg = textBase["mainPage"][selectedLanguage]["error"][3]; //"Tire data for rear right is missing!"
    }

    if (
      (mode == "engineType" && (value == "" || value == 0)) ||
      ((dataframe.main["engineType"] == "" ||
        dataframe.main["engineType"] == 0) &&
        mode != "engineType") //first condition: "realtime"-feedback for the user / second condition: check on commit (=> mode != "engineType", but mode == "commit")
    ) {
      warningMsg = textBase["mainPage"][selectedLanguage]["error"][8]; //"Please choose the engine type!"
    }

    if (
      (mode == "carManufacturer" && (value == "" || value == 0)) ||
      ((dataframe.main["carManufacturer"] == "" ||
        dataframe.main["carManufacturer"] == 0) &&
        mode != "carManufacturer") //first condition: "realtime"-feedback for the user / second condition: check on commit (=> mode != "carManufacturer", but mode == "commit")
    ) {
      warningMsg = textBase["mainPage"][selectedLanguage]["error"][7]; //"Please choose the car manufacturer!"
    }

    if (
      (mode == "workshopAction" && (value == "" || value == 0)) ||
      ((dataframe.main["workshopAction"] == "" ||
        dataframe.main["workshopAction"] == 0) &&
        mode != "workshopAction") //first condition: "realtime"-feedback for the user / second condition: check on commit (=> mode != "workshopAction", but mode == "commit")
    ) {
      warningMsg = textBase["mainPage"][selectedLanguage]["error"][6]; //"Please choose the reason for visiting the workshop!"
    }

    if (
      (mode == "workshopName" && value == "") ||
      (dataframe.main["workshopName"] == "" && mode != "workshopName")
    ) {
      warningMsg = textBase["mainPage"][selectedLanguage]["error"][5]; //"Please enter the name of the workshop!"
    }

    if (
      (mode == "country" && (value == "" || value == 0)) ||
      ((dataframe.main["country"] == "" || dataframe.main["country"] == 0) &&
        mode != "country") //first condition: "realtime"-feedback for the user / second condition: check on commit (=> mode != "country", but mode == "commit")
    ) {
      warningMsg = textBase["mainPage"][selectedLanguage]["error"][4]; //"Please choose your country!"
    }

    if (warningMsg.length == 0) {
      setIsAlertVisible(false); //no more alerts displayed
      if (mode == "commit") {
        createDatabaseEntry(); //triggers the creation of a new database entry
        sendEmail(Constants.MAILADDRESS); //send email after data was pushed to the database to notify the Conti-admins
        dataToCommit(); //transmit language information to the "end"-page
      }
    } else {
      setIsAlertVisible(true); //there is still information missing
      console.log(warningMsg);
    }
  }

  /**
   * This function checks if the user is part of the Cognito admin group or the workshop group instead.
   *
   * @name checkCognitoAdminGroup
   * @memberof module:Mainpage
   * @return {boolean} 0: user is not part of the admin group, 1: user is part of the admin group
   *
   * @example
   *
   *     checkCognitoAdminGroup()
   */
  async function checkCognitoAdminGroup() {
    //check Group => if part of Admin display button to navigate to the admin page
    const userInfo = await Auth.currentAuthenticatedUser({ bypassCache: true });
    var partOfAdmin =
      userInfo.signInUserSession.idToken.payload["cognito:groups"][0] ==
      "Admin";
    return partOfAdmin;
  }

  checkCognitoAdminGroup().then((data) => {
    setCognitoGroup(data); //if this is set, a new button is displayed at the end of the page => button for navigating to the admin page => only users of the admin group are allowed to switch to the admin page.
  });

  return (
    <View
      width={Constants.WINDOW_WIDTH_FULL}
      left={Constants.WINDOW_LEFT}
      height="auto" //dynamically resizes the height of the page - this is needed because of the dynamically rendered popups (e.g. alerts in different sizes)
      overflow="hidden"
      position="relative"
      backgroundColor="rgba(255,255,255,1)"
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <Flex direction="column">
        <Image
          width="202px"
          height="62px"
          position="relative"
          left="5%"
          src={contiLogo_img}
        ></Image>

        <Flex direction="column" gap="0px">
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            display="flex"
            gap="0px"
          >
            <Text
              className="text-element"
              width={Constants.WINDOW_WIDTH}
              children={textBase["mainPage"][selectedLanguage]["countryLabel"]}
            ></Text>
            <SelectField
              className="select-field"
              width={Constants.WINDOW_WIDTH}
              size="small"
              options={textBase["mainPage"][selectedLanguage]["countryArr"]}
              value={
                textBase["mainPage"][selectedLanguage]["countryArr"][country]
              }
              onChange={(event) => {
                setCountry(
                  textBase["mainPage"][selectedLanguage]["countryArr"].indexOf(
                    String(event.target.value)
                  )
                  //by using the "indexOf"-function instead of the value itself (=>String) the index is stored => this is necessary for the multi-language solution
                );
                setData();
                checkDataInput("country", event.target.value);
              }}
            ></SelectField>

            <Text
              className="text-element"
              width={Constants.WINDOW_WIDTH}
              children={
                textBase["mainPage"][selectedLanguage]["workshopNameLabel"]
              }
            ></Text>
            <TextField
              className="text-field"
              width={Constants.WINDOW_WIDTH}
              size="small"
              defaultValue={location.state.main["workshopName"]}
              onChange={(event) => {
                if (event.target.value == "") {
                  setWorkshopName(""); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
                } else {
                  setWorkshopName(event.target.value);
                }
                setData();
                checkDataInput("workshopName", event.target.value);
              }}
            ></TextField>

            <Text
              className="text-element"
              width={Constants.WINDOW_WIDTH}
              children={
                textBase["mainPage"][selectedLanguage]["workshopActionLabel"]
              }
            ></Text>
            <SelectField
              className="select-field"
              width={Constants.WINDOW_WIDTH}
              size="small"
              options={
                textBase["mainPage"][selectedLanguage]["reasonForVisitingArr"]
              }
              value={
                isOthersSelectedreasonForVisiting
                  ? textBase["mainPage"][selectedLanguage][
                      "reasonForVisitingArr"
                    ][4]
                  : textBase["mainPage"][selectedLanguage][
                      "reasonForVisitingArr"
                    ][workshopVisitReason]
              }
              onChange={(event) => {
                if (
                  event.target.value ==
                  textBase["mainPage"][selectedLanguage][
                    "reasonForVisitingArr"
                  ][4]
                ) {
                  isOthersSelectedreasonForVisiting = true;
                  setworkshopVisitReason("");
                } else {
                  isOthersSelectedreasonForVisiting = false;
                  setworkshopVisitReason(
                    textBase["mainPage"][selectedLanguage][
                      "reasonForVisitingArr"
                    ].indexOf(String(event.target.value))
                  );
                }
                setData();
                checkDataInput("workshopAction", event.target.value);
              }}
            ></SelectField>
          </Flex>

          {isOthersSelectedreasonForVisiting ? (
            <Flex
              direction="column"
              gap="0px"
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <Text
                className="text-element"
                paddingTop="10px" //overwrite CSS style (padding-block-start => CSS variable for paddingTop), because the padding should be smaller for the popup of the "Others"-textfield
                width={Constants.WINDOW_WIDTH}
                children={
                  textBase["mainPage"][selectedLanguage][
                    "workshopActionLabelOthers"
                  ]
                }
              ></Text>
              <TextField
                className="text-field"
                width={Constants.WINDOW_WIDTH}
                size="small"
                defaultValue={workshopVisitReason} //{location.state.main["workshopAction"]}
                onChange={(event) => {
                  if (event.target.value == "") {
                    setworkshopVisitReason(""); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
                  } else {
                    setworkshopVisitReason(event.target.value);
                  }
                  setData();
                  checkDataInput("workshopAction", event.target.value);
                }}
              ></TextField>
            </Flex>
          ) : null}

          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            display="flex"
            gap="0px"
          >
            <Text
              className="text-element"
              width={Constants.WINDOW_WIDTH}
              children={
                textBase["mainPage"][selectedLanguage]["carManufacturerLabel"]
              }
            ></Text>
            <SelectField
              className="select-field"
              width={Constants.WINDOW_WIDTH}
              size="small"
              value={
                isOthersSelectedCarManuf
                  ? textBase["mainPage"][selectedLanguage][
                      "reasonForVisitingArr"
                    ][4] //== "Others"
                  : textBase["mainPage"]["carManufacturersArr"][vehicleBrand]
              }
              options={textBase["mainPage"]["carManufacturersArr"].concat(
                textBase["mainPage"][selectedLanguage][
                  "reasonForVisitingArr"
                ][4] //concatenate "Others"-field
              )}
              onChange={(event) => {
                if (
                  event.target.value ==
                  textBase["mainPage"][selectedLanguage][
                    "reasonForVisitingArr"
                  ][4]
                ) {
                  isOthersSelectedCarManuf = true;
                  setVehicleBrand("");
                } else {
                  isOthersSelectedCarManuf = false;
                  setVehicleBrand(
                    textBase["mainPage"]["carManufacturersArr"].indexOf(
                      String(event.target.value)
                    )
                  );
                }
                setData();
                checkDataInput("carManufacturer", event.target.value);
              }}
            ></SelectField>
          </Flex>

          {isOthersSelectedCarManuf ? (
            <Flex
              direction="column"
              gap="0px"
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <Text
                className="text-element"
                paddingTop="10px" //overwrite CSS style (padding-block-start => CSS variable for paddingTop), because the padding should be smaller for the popup of the "Others"-textfield
                width={Constants.WINDOW_WIDTH}
                children={
                  textBase["mainPage"][selectedLanguage][
                    "carManufacturerLabelOthers"
                  ]
                }
              ></Text>
              <TextField
                className="text-field"
                width={Constants.WINDOW_WIDTH}
                size="small"
                defaultValue={vehicleBrand} //{location.state.main["carManufacturer"]}
                onChange={(event) => {
                  if (event.target.value == "") {
                    setVehicleBrand(""); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
                  } else {
                    setVehicleBrand(event.target.value);
                  }
                  setData();
                  checkDataInput("vehicleBrand", event.target.value);
                }}
              ></TextField>
            </Flex>
          ) : null}

          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            display="flex"
            gap="0px"
          >
            <Text
              className="text-element"
              width={Constants.WINDOW_WIDTH}
              children={
                textBase["mainPage"][selectedLanguage]["engineTypeLabel"]
              }
            ></Text>
            <SelectField
              className="select-field"
              width={Constants.WINDOW_WIDTH}
              size="small"
              value={
                isOthersSelectedEngineType
                  ? textBase["mainPage"][selectedLanguage][
                      "reasonForVisitingArr"
                    ][4] //== "Others"
                  : textBase["mainPage"][selectedLanguage]["engineTypeArr"][
                      engineType
                    ]
              }
              options={textBase["mainPage"][selectedLanguage]["engineTypeArr"]}
              onChange={(event) => {
                if (
                  event.target.value ==
                  textBase["mainPage"][selectedLanguage][
                    "reasonForVisitingArr"
                  ][4]
                ) {
                  isOthersSelectedEngineType = true;
                  setEngineType("");
                } else {
                  isOthersSelectedEngineType = false;
                  setEngineType(
                    textBase["mainPage"][selectedLanguage][
                      "engineTypeArr"
                    ].indexOf(String(event.target.value))
                  );
                }
                setData();
                checkDataInput("engineType", event.target.value);
              }}
            ></SelectField>
          </Flex>

          {isOthersSelectedEngineType ? (
            <Flex
              direction="column"
              gap="0px"
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <Text
                className="text-element"
                paddingTop="10px" //overwrite CSS style (padding-block-start => CSS variable for paddingTop), because the padding should be smaller for the popup of the "Others"-textfield
                width={Constants.WINDOW_WIDTH}
                children={
                  textBase["mainPage"][selectedLanguage]["engineTypeOthers"]
                }
              ></Text>
              <TextField
                className="text-field"
                width={Constants.WINDOW_WIDTH}
                size="small"
                defaultValue={engineType} //{location.state.main["engineType"]}
                onChange={(event) => {
                  if (event.target.value == "") {
                    setEngineType(""); //if input gets deleted, the "empty" event.target.value somehow does not contain the default ""-String
                  } else {
                    setEngineType(event.target.value);
                  }
                  setData();
                  checkDataInput("engineType", event.target.value);
                }}
              ></TextField>
            </Flex>
          ) : null}
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            display="flex"
            gap="0px"
          >
            <Text
              className="text-element"
              paddingTop="30px" //overwrite CSS style (padding-block-start => CSS variable for paddingTop), because the padding should be smaller for the popup of the "Others"-textfield
              width={Constants.WINDOW_WIDTH}
              children={textBase["mainPage"][selectedLanguage]["userInfoLabel"]}
            ></Text>
          </Flex>

          <Flex
            direction="column"
            gap="0px"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Image
              width="120px"
              position="relative"
              src={carDrawing_img}
            ></Image>

            <Button
              className="tire-button"
              top="-180px"
              left={{
                base: "-8%",
                small: "-34px",
                medium: "-34px",
                large: "-34px",
                xl: "-34px",
                xxl: "-34px",
              }}
              backgroundColor={startBagdeFLBackgroundColor}
              onClick={() => {
                setData();
                dataToFL(); //navigate + "send" dataframe to the tire detail page
              }}
              onMouseEnter={() => {
                startBagdeFLOnMouseEnter(); //sets the button-color for hover mode
              }}
              onMouseLeave={() => {
                startBagdeFLOnMouseLeave(); //sets the button-color when mouse is leaving again
              }}
            ></Button>

            <Button
              className="tire-button"
              top="-205px"
              left={{
                base: "15%",
                small: "65px",
                medium: "65px",
                large: "65px",
                xl: "65px",
                xxl: "65px",
              }}
              backgroundColor={startBadgeFRBackgroundColor}
              onClick={() => {
                setData();
                dataToFR(); //see above
              }}
              onMouseEnter={() => {
                startBadgeFROnMouseEnter(); //see above
              }}
              onMouseLeave={() => {
                startBadgeFROnMouseLeave(); //see above
              }}
            ></Button>

            <Button
              className="tire-button"
              top="-95px"
              left={{
                base: "-8%",
                small: "-36px",
                medium: "-36px",
                large: "-36px",
                xl: "-36px",
                xxl: "-36px",
              }}
              backgroundColor={startBadgeRLBackgroundColor}
              onClick={() => {
                setData();
                dataToRL(); //see above
              }}
              onMouseLeave={() => {
                startBadgeRLOnMouseLeave(); //see above
              }}
              onMouseEnter={() => {
                startBadgeRLOnMouseEnter(); //see above
              }}
            ></Button>

            <Button
              className="tire-button"
              top="-120px"
              left={{
                base: "15%",
                small: "65px",
                medium: "65px",
                large: "65px",
                xl: "65px",
                xxl: "65px",
              }}
              backgroundColor={startBadgeRRBackgroundColor}
              onClick={() => {
                setData();
                dataToRR(); //see above
              }}
              onMouseLeave={() => {
                startBadgeRROnMouseLeave(); //see above
              }}
              onMouseEnter={() => {
                startBadgeRROnMouseEnter(); //see above
              }}
            ></Button>
          </Flex>
          <Flex
            direction="column"
            gap="0px"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            {isAlertVisible ? (
              <Alert
                className="info-alert"
                display="flex"
                variation="error"
                position="relative"
                width={Constants.WINDOW_WIDTH}
                justifyContent="center"
                alignItems="center"
                top="-60px"
                children={warningMsg}
                isDismissible={false}
                hasIcon={true}
              ></Alert>
            ) : null}

            <Button
              className="commit-button"
              width={Constants.WINDOW_WIDTH}
              top="-50px"
              justifyContent="center"
              alignItems="center"
              display="flex"
              children={
                textBase["mainPage"][selectedLanguage]["commitButtonLabel"]
              }
              onClick={() => {
                setData();
                checkDataInput("commit"); //after check the database entry is created
              }}
            ></Button>
            <Divider
              label=""
              orientation="horizontal"
              width={Constants.WINDOW_WIDTH}
              position="relative"
            ></Divider>
          </Flex>

          <Flex
            direction="column"
            position="relative"
            top="10px"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Image
              width={Constants.WINDOW_WIDTH}
              position="relative"
              top="2px"
              src={flags_img}
            ></Image>

            <Divider
              className="language-divider-underlay"
              orientation="horizontal"
              borderRadius="10px"
              top="-6px"
              width={Constants.WINDOW_WIDTH}
            ></Divider>

            <Flex
              direction="row"
              justifyContent="space-between"
              position="relative"
              top={{
                base: "-75px",
                small: "-70px",
                medium: "-70px",
                large: "-70px",
                xl: "-70px",
                xxl: "-70px",
              }}
              width={Constants.WINDOW_WIDTH}
            >
              <Button
                className="language-button"
                onClick={() => {
                  selectedLanguage = "english";
                  setLanguage("english");
                }}
              ></Button>

              <Button
                className="language-button"
                onClick={() => {
                  selectedLanguage = "german";
                  setLanguage("german");
                }}
              ></Button>

              <Button
                className="language-button"
                onClick={() => {
                  selectedLanguage = "italian";
                  setLanguage("italian");
                }}
              ></Button>

              <Button
                className="language-button"
                onClick={() => {
                  selectedLanguage = "spanish";
                  setLanguage("spanish");
                }}
              ></Button>

              <Button
                className="language-button"
                onClick={() => {
                  selectedLanguage = "french";
                  setLanguage("french");
                }}
              ></Button>
            </Flex>
          </Flex>
          <Flex
            direction="row"
            justifyContent="center"
            position="relative"
            top="-70px"
            left={languageBarPosition[selectedLanguage]}
          >
            <Divider
              className="language-divider"
              orientation="horizontal"
              borderRadius="10px"
              width="38px"
              top="15px"
            ></Divider>
          </Flex>

          <Flex
            direction="column"
            justifyContent="center"
            position="relative"
            top="-35px"
            gap="6px"
            alignItems="center"
            display="flex"
          >
            <Button
              className="imprint-button"
              size="small"
              children={textBase["legal"][selectedLanguage]["imprint"][0]}
              width={Constants.WINDOW_WIDTH}
              onClick={() => {
                setIsImprintVisible(!isImprintVisible); //activates (=shows) the imprint
                setIsDataPolicyVisible(false); //hides the other data policy panel
                setIsInfoAlertVisible(false); //hides the other info alert panel
              }}
            ></Button>

            <Button
              className="imprint-button"
              size="small"
              children={textBase["legal"][selectedLanguage]["copyright"][0]}
              width={Constants.WINDOW_WIDTH}
              onClick={() => {
                setIsDataPolicyVisible(!isDataPolicyVisible); //see above
                setIsImprintVisible(false); //see above
                setIsInfoAlertVisible(false); //see above
              }}
            ></Button>

            <Button
              className="contact-button"
              size="small"
              children={textBase["legal"][selectedLanguage]["contact"][0]}
              width={Constants.WINDOW_WIDTH}
              onClick={() => {
                setIsInfoAlertVisible(!isInfoAlertVisible); //see above
                setIsImprintVisible(false); //see above
                setIsDataPolicyVisible(false); //see above
              }}
            ></Button>

            {cognitoGroup ? (
              <Button
                className="admin-button"
                size="small"
                children="Administrate TDM Webapp"
                width={Constants.WINDOW_WIDTH}
                onClick={() => {
                  //console.log("jeyy");
                  adminNav();
                }}
              ></Button>
            ) : null}
          </Flex>

          <Flex
            direction="column"
            position="relative"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            {isInfoAlertVisible ? (
              <Alert
                className="info-alert"
                variation="info"
                width={Constants.WINDOW_WIDTH}
                top="-25px"
                children={textBase["legal"][selectedLanguage]["contact"][1]}
              ></Alert>
            ) : null}

            {isImprintVisible ? (
              <Alert
                className="info-alert"
                width={Constants.WINDOW_WIDTH}
                heading={textBase["legal"][selectedLanguage]["imprint"][0]}
                //top="-25px"
              >
                <p>{textBase["legal"][selectedLanguage]["imprint"][1]}</p>
                {textBase["legal"][selectedLanguage]["imprint"][2]}
                <p></p>
                {textBase["legal"][selectedLanguage]["imprint"][3]}
                <p></p>
                {textBase["legal"][selectedLanguage]["imprint"][4]}
                <p></p>
                {textBase["legal"][selectedLanguage]["imprint"][5]}
                <p></p>
                {textBase["legal"][selectedLanguage]["imprint"][6]}
              </Alert>
            ) : null}

            {isDataPolicyVisible ? (
              <Alert
                className="info-alert"
                width={Constants.WINDOW_WIDTH}
                heading={textBase["legal"][selectedLanguage]["copyright"][0]}
              >
                <Markdown children={dataPolicy} remarkPlugins={[remarkGfm]} />
              </Alert>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </View>
  );
}
